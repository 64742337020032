import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop'
import * as DashboardAPI from 'api/dashboard'
import { handleApiError } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopState = {
  isRequesting: boolean
  errorMessage: string
  bop: API.BopData | undefined
  updateBopResponse: API.UpdateBopResponse | undefined
  profitAndLoss: DashboardAPI.BopProfitAndLossResponse | undefined
}

const initialState: BopState = {
  isRequesting: false,
  errorMessage: '',
  bop: undefined,
  updateBopResponse: undefined,
  profitAndLoss: undefined,
}

export const bopSlice = createSlice({
  name: 'bop',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopSuccess: (state, action: PayloadAction<API.BopResponse>) => {
      state.isRequesting = false
      state.bop = action.payload.bop
    },
    updateBopSuccess: (state, action: PayloadAction<API.UpdateBopResponse>) => {
      state.isRequesting = false
      state.updateBopResponse = action.payload
    },
    getBopProfitAndLossSuccess: (state, action: PayloadAction<DashboardAPI.BopProfitAndLossResponse>) => {
      state.isRequesting = false
      state.profitAndLoss = action.payload
    },
  },
})

export const { startRequest, apiFailure, getBopSuccess, updateBopSuccess, getBopProfitAndLossSuccess } =
  bopSlice.actions

export const getBop =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getBop(workspaceId)
      .then((res: API.BopResponse) => dispatch(getBopSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateBop =
  (workspaceId: number, requestBody: API.UpdateBopRequest): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateBop(workspaceId, requestBody)
      .then((res: API.UpdateBopResponse) => dispatch(updateBopSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getBopProfitAndLoss =
  (date: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    DashboardAPI.getBopProfitAndLoss(date)
      .then((res: DashboardAPI.BopProfitAndLossResponse) => dispatch(getBopProfitAndLossSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectBopStatus = (state: RootState) => ({ ...state.bop })

export default bopSlice.reducer
