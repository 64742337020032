import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, DeleteResponse, UpdateResponse } from './types'

export type PartialOfficialDutiesListData = {
  id: number
  name: string
  workDate: string
  revision: number
  updatedAt: string
  updatedByName: string
}

export type OfficialDutiesListResponse = {
  partialTenant: PartialTenantData
  partialOfficialDutiesList: PartialOfficialDutiesListData[]
}

export type OfficialDutiesData = PartialOfficialDutiesListData & {
  averageHourlyWage: number
}

export type OfficialDutiesDetailResponse = {
  workDate: string
  tenant: TenantData
  officialDuties: OfficialDutiesData
}

export type OfficialDutiesEditData = {
  name: string
  averageHourlyWage: number
}

export const getOfficialDutiesList = (): Promise<OfficialDutiesListResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/official-duties'

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getOfficialDuties = (id: number): Promise<OfficialDutiesDetailResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/official-duties/${id}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createOfficialDuties = (data: OfficialDutiesEditData): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/official-duties'

    axiosClientWithTenantId
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateOfficialDuties = (id: number, data: OfficialDutiesEditData): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/official-duties/${id}`

    axiosClientWithTenantId
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteOfficialDuties = (id: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `/official-duties/${id}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
