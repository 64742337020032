import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/groups'
import { handleApiError } from 'api/utils'

import type { ColorType } from 'components/common/types'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type AllGroupType = {
  groups: API.GroupData[]
  workspaceId: number
}

type GroupsState = {
  isRequesting: boolean
  errorMessage: string
  group?: API.GroupData
  groups: API.GroupData[]
  allGroups: AllGroupType[]
  groupWorkspaceId: number | undefined
}

const initialState: GroupsState = {
  isRequesting: false,
  errorMessage: '',
  group: undefined,
  groups: [],
  allGroups: [],
  groupWorkspaceId: undefined,
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getGroupListSuccess: (state, action: PayloadAction<API.GroupListResponse>) => {
      state.isRequesting = false
      state.groups = _.sortBy(action.payload.workGroups, 'name')
      state.groupWorkspaceId = action.payload.partialWorkspace.id
    },
    getGroupSuccess: (state, action: PayloadAction<API.GroupResponse>) => {
      state.isRequesting = false
      state.group = action.payload.workGroup
    },
    createGroupSuccess: state => {
      state.isRequesting = false
    },
    updateGroupSuccess: state => {
      state.isRequesting = false
    },
    deleteGroupSuccess: state => {
      state.isRequesting = false
    },
    getAllGroupsSuccess: (state, action: PayloadAction<AllGroupType[]>) => {
      state.isRequesting = false
      state.allGroups = action.payload
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getGroupListSuccess,
  getGroupSuccess,
  createGroupSuccess,
  updateGroupSuccess,
  deleteGroupSuccess,
  getAllGroupsSuccess,
} = groupsSlice.actions

export const getGroupList =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getGroupList(workspaceId)
      .then((res: API.GroupListResponse) => dispatch(getGroupListSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getGroup(workspaceId, groupId)
      .then((res: API.GroupResponse) => dispatch(getGroupSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createGroup =
  (workspaceId: number, name: string, color: ColorType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createGroup(workspaceId, name, color)
      .then(() => {
        dispatch(createGroupSuccess())
        dispatch(getGroupList(workspaceId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateGroup =
  (workspaceId: number, groupId: number, name: string, color: ColorType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateGroup(workspaceId, groupId, name, color)
      .then(() => {
        dispatch(updateGroupSuccess())
        dispatch(getGroupList(workspaceId))
        dispatch(getGroup(workspaceId, groupId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteGroup(workspaceId, groupId)
      .then(() => {
        dispatch(deleteGroupSuccess())
        dispatch(getGroupList(workspaceId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getAllGroups =
  (workspaceIds: number[]): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    const promises: Promise<AllGroupType>[] = workspaceIds.map(workspaceId =>
      API.getGroupList(workspaceId).then(res => ({
        groups: res.workGroups,
        workspaceId,
      }))
    )

    await Promise.all(promises)
      .then((res: AllGroupType[]) => dispatch(getAllGroupsSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectGroupsStatus = (state: RootState) => ({ ...state.groups })

export default groupsSlice.reducer
