import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { handleApiError } from 'api/utils'
import type { PartialWorkspaceData, WorkspaceData } from 'api/workspaces'
import * as API from 'api/workspaces'

import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type WorkspacesState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkspaces: PartialWorkspaceData[]
  workspace?: WorkspaceData
  workspaceExists: boolean
}

const initialState: WorkspacesState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkspaces: [],
  workspace: undefined,
  workspaceExists: false,
}

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkspaceListSuccess: (state, action: PayloadAction<API.WorkspaceListResponse>) => {
      state.isRequesting = false
      state.partialWorkspaces = _.sortBy(
        action.payload.partialWorkspaces.map(workspace => ({
          ...workspace,
          scheduleTypeNames: _.sortBy(workspace.scheduleTypeNames),
        })),
        'name'
      )
    },
    getWorkspaceSuccess: (state, action: PayloadAction<API.WorkspaceResponse>) => {
      state.isRequesting = false
      state.workspace = action.payload.workspace
    },
    workspaceExistsSuccess: state => {
      state.isRequesting = false
      state.workspaceExists = true
    },
    workspaceExistsError: state => {
      state.isRequesting = false
      state.workspaceExists = false
    },
    requestSuccess: state => {
      state.isRequesting = false
    },
    clearWorkspaceList: state => {
      state.partialWorkspaces = []
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getWorkspaceListSuccess,
  getWorkspaceSuccess,
  requestSuccess,
  clearWorkspaceList,
  workspaceExistsSuccess,
  workspaceExistsError,
} = workspacesSlice.actions

export const getWorkspaceList =
  (data?: { workDate?: string; tenantId?: number }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const tenantId = data?.tenantId ?? commonParams(getState).tenantId

    API.getWorkspacesList(tenantId, data?.workDate)
      .then((res: API.WorkspaceListResponse) => dispatch(getWorkspaceListSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getWorkspace =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getWorkspace(workspaceId)
      .then((res: API.WorkspaceResponse) => dispatch(getWorkspaceSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const checkWorkspaceExists =
  (workspaceId: number, date?: string, callback?: (workspaceExits: boolean) => void): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getWorkspace(workspaceId, date)
      .then(() => {
        dispatch(workspaceExistsSuccess())
        callback?.(true)
      })
      .catch(() => {
        dispatch(workspaceExistsError())
        callback?.(false)
      })
  }

export const createWorkspace =
  (data: API.EditWorkspaceProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createWorkspace(data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateWorkspace =
  (workspaceId: number, data: API.EditWorkspaceProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateWorkspace(workspaceId, data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
        dispatch(getWorkspace(workspaceId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteWorkspace =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteWorkspace(workspaceId)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkspaceList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const csvImportTargets =
  (workspaceId: string, fileData: FormData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.uploadTargets(workspaceId, fileData)
      dispatch(requestSuccess())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectWorkspacesStatus = (state: RootState) => ({ ...state.workspaces })

export default workspacesSlice.reducer
