import type {
  BopMonitoringReportParam,
  BopReportsBopResponse,
  BopReportsLaborCostsResponse,
  BopReportsProfitAndLossResponse,
  BopReportsUnitCostsResponse,
  BopReportUnitCostsParam,
  CheckExportDataReadyResponse,
  CreateBopExportDataInfo,
  CreateExportDataResponse,
} from 'api/bop_reports/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

export const getBopReportsProfitAndLoss = (from: string, to: string): Promise<BopReportsProfitAndLossResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/bop-report-detail?from=${from}&to=${to}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsBop = (data: BopMonitoringReportParam): Promise<BopReportsBopResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/bop-report'

    axiosClientWithTenantId
      .get(url, { params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsLaborCosts = (data: BopMonitoringReportParam): Promise<BopReportsLaborCostsResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/bop-report-labor-costs'

    axiosClientWithTenantId
      .get(url, { params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsUnitCosts = (data: BopReportUnitCostsParam): Promise<BopReportsUnitCostsResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/bop-report-unit-costs'

    axiosClientWithTenantId
      .get(url, { params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createBopExportData = (data: CreateBopExportDataInfo): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = '/bop-export/async'

    axiosClientWithTenantId
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getBopExportData = (requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `/bop-export/update-status/${requestId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
