import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/allocations'
import { handleApiError } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type AllocationState = {
  isRequesting: boolean
  errorMessage: string
  allocation?: API.AllocationData
  partialAllocations: API.PartialAllocationData[]
  workspaceId: number | null
}

const initialState: AllocationState = {
  isRequesting: false,
  errorMessage: '',
  allocation: undefined,
  partialAllocations: [],
  workspaceId: null,
}

export const allocationSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },

    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getAllocationListSuccess: (state, action: PayloadAction<API.AllocationListResponse>) => {
      state.isRequesting = false
      state.partialAllocations = _.sortBy(action.payload.partialAllocations, 'name')
    },
    getAllocationSuccess: (state, action: PayloadAction<API.AllocationResponse>) => {
      state.isRequesting = false
      state.allocation = action.payload.allocation
    },
    createAllocationSuccess: state => {
      state.isRequesting = false
    },
    updateAllocationSuccess: state => {
      state.isRequesting = false
    },
    deleteAllocationSuccess: state => {
      state.isRequesting = false
    },
    storeWorkspaceId: (state, action: PayloadAction<number>) => {
      state.workspaceId = action.payload
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getAllocationListSuccess,
  getAllocationSuccess,
  createAllocationSuccess,
  updateAllocationSuccess,
  deleteAllocationSuccess,
  storeWorkspaceId,
} = allocationSlice.actions

export const getAllocationList =
  (workspaceId: number, callback?: (allocationList: API.PartialAllocationData[]) => void): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    API.getAllocationList(workspaceId)
      .then((res: API.AllocationListResponse) => {
        callback?.(res.partialAllocations)
        dispatch(getAllocationListSuccess(res))
        dispatch(storeWorkspaceId(workspaceId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getAllocation(workspaceId, allocationId)
      .then((res: API.AllocationResponse) => dispatch(getAllocationSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createAllocation =
  (workspaceId: number, data: API.UpdateAllocationProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createAllocation(workspaceId, data)
      .then(() => dispatch(createAllocationSuccess()))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateAllocation =
  (workspaceId: number, allocationId: number, data: API.UpdateAllocationProps): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateAllocation(workspaceId, allocationId, data)
      .then(() => dispatch(updateAllocationSuccess()))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteAllocation =
  (workspaceId: number, allocationId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteAllocation(workspaceId, allocationId)
      .then(() => dispatch(deleteAllocationSuccess()))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectAllocationStatus = (state: RootState) => ({ ...state.allocations })

export default allocationSlice.reducer
