import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  AvailableSpotWorkersResponse,
  CreateSpotWorkersResponse,
  ImportSpotWorkersResponse,
  ImportSpotWorkerStatusResponse,
  SaveSpotWorkerResponse,
  SpotWorkerListResponse,
  SpotWorkersSaveDataType,
} from './types'

export const getSpotWorkers = (workDate: string): Promise<SpotWorkerListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const addSpotWorker = (workerIds: number[], workDate: string): Promise<CreateSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}`

    axiosClientWithTenantId
      .post(url, { workerIds })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const importSpotWorkers = (data: FormData, workDate: string): Promise<ImportSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/import/async`
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    axiosClientWithTenantId
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getSpotWorkerImportStatus = (
  workDate: string,
  requestId: string
): Promise<ImportSpotWorkerStatusResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/import/${requestId}/import-status`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAvailableSpotWorkers = (workDate: string): Promise<AvailableSpotWorkersResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/available-workers`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const saveSpotWorker = (
  data: SpotWorkersSaveDataType,
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`

    axiosClientWithTenantId
      .put(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteSpotWorker = (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateSpotWorker = (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/line-update`

    axiosClientWithTenantId
      .put(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const SickoutSpotWorker = (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/sickout`

    axiosClientWithTenantId
      .put(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const assignSpotWorkerNewId = (
  workDate: string,
  lineNumber: number,
  revision: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/new`

    axiosClientWithTenantId
      .put(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const assignSpotWorkerExistingId = (
  workDate: string,
  lineNumber: number,
  revision: number,
  workerId: number
): Promise<SaveSpotWorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/spot-workers/work-date/${workDate}/line-number/${lineNumber}/revisions/${revision}/assign`

    axiosClientWithTenantId
      .put(url, { workerId })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
