import type { ColorType } from 'components/common/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { WorkerType } from './workers/types'
export type ReportAverageResponse = {
  data: ReportAverage[]
}

export type ReportAverage = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  hourlyAvgProductivity: number | null
  dailyAvgPlanAccuracy: number | null
}

export type ReportProductivityResponse = {
  workspaceId: number
  workspaceName: string
  groups: ProductivityGroup[]
  dailyWorkData: DailyWork[]
}

export type ProductivityGroup = {
  groupId: number | null
  groupName: string | null
  supportedWorkspaceId: number | null
  supportedWorkspaceName: string | null
  workers: ProductivityWorker[]
}

export type ProductivityWorker = {
  workerId: number
  workerName: string
  workerWmsMemberId: string
  workerType: WorkerType
  dailyWorkData: DailyWork[]
}

export type DailyWork = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  hourlyAvgProductivity: number | null
  data: DailyWorkRow[]
}

export type DailyWorkRow = {
  date: string
  dailyAvgPlanCount: number | null
  dailyAvgRecordCount: number | null
}

export type HourlyPlanAccuracyRow = {
  date: string
  archivePlanCount: number | null
  lastPlanCount: number | null
}

export type HourlyPlanAccuracy = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  data: HourlyPlanAccuracyRow[]
}

export type DailyPlanAccuracyRow = {
  workDate: string
  value: number | null
}

export type DailyPlanAccuracy = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  data: DailyPlanAccuracyRow[]
}

export type ReportDailyPlanAccuracyResponse = {
  data: DailyPlanAccuracy[]
}

export type ReportHourlyPlanAccuracyResponse = {
  data: HourlyPlanAccuracy[]
}

export const ExportDataTypes = {
  PlanAndRecord: 'planAndRecord',
  PlanDetail: 'planDetail',
} as const

export type ExportDataType = (typeof ExportDataTypes)[keyof typeof ExportDataTypes]

export type CreateExportDataInfo = {
  startDate: string
  endDate: string
  targetWorkspaces: number[]
  includeProductivityValue: boolean
  includeRecord?: boolean
  exportDataType: ExportDataType
}

export type CreateExportDataResponse = {
  requestId: string
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export const getReportAverage = (workspaceId: number, from: string, to: string): Promise<ReportAverageResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/report-average`

    axiosClientWithTenantId
      .get(url, { params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportProductivity = (
  workspaceId: number,
  from: string,
  to: string
): Promise<ReportProductivityResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/report-productivity`

    axiosClientWithTenantId
      .get(url, { params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportPlanAccuracy = (
  workspaceId: number,
  from: string,
  to: string
): Promise<ReportDailyPlanAccuracyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/report-plan-accuracy`

    axiosClientWithTenantId
      .get(url, { params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportHourlyPlanAccuracy = (
  workspaceId: number,
  date: string
): Promise<ReportHourlyPlanAccuracyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${date}/report-hourly-plan-accuracy`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createExportData = (data: CreateExportDataInfo): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = '/data-export/async'

    axiosClientWithTenantId
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getExportData = (requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `/data-export/update-status/${requestId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
