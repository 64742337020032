import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { TenantData } from './tenants'
import type { WorkspaceData } from './workspaces'

export type CostArray = {
  name: string
  cost: number
}

export type UnitCostsArray = {
  scheduleTypeId: number
  scheduleTypeName: string
  unit: string
  unitCost: number
  materialCosts: CostArray[]
}

export type ScheduleTypesArray = {
  name: string
  color: string
}

export type BopData = {
  workDate: string
  revision: number
  unitCosts: UnitCostsArray[]
  otherSales: CostArray[]
  managementCosts: CostArray[]
  extraCostScheduleTypes: ScheduleTypesArray[]
  workspaceCosts: CostArray[]
  updatedAt: string
  updatedByName: string
}

export type BopResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
  bop: BopData
}

type UnitCostsArrayForRequest = {
  scheduleTypeId: number
  unitCost: number
  materialCosts: CostArray[]
}

export type UpdateBopRequest = {
  workDate?: string
  unitCosts: UnitCostsArrayForRequest[]
  otherSales: CostArray[]
  managementCosts: CostArray[]
  workspaceCosts: CostArray[]
}

export type UpdateBopResponse = {
  revision: number
  workDate: string
  updatedAt: string
  updatedByName: string
}

export const getBop = (workspaceId: number): Promise<BopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/bop`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateBop = (workspaceId: number, requestBody: UpdateBopRequest): Promise<UpdateBopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/bop`

    axiosClientWithTenantId
      .patch(url, requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
