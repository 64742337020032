import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { updateTemplateData, selectTemplateStatus } from 'slices/templateSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  workspaceId: number
  templateId: number | undefined
  templateName: string | undefined
  onSuccess: () => void
  onCancel: () => void
}

const TemplateDelete: React.FC<Props> = ({ isOpen, workspaceId, templateId, templateName, onSuccess, onCancel }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectTemplateStatus, shallowEqual)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const dispatch = useDispatch()

  const handleDeleteClick = () => {
    if (templateId) {
      setSubmitted(true)
      dispatch(
        updateTemplateData(workspaceId, {
          id: templateId,
          name: templateName,
          schedules: null,
          workspaces: null,
        })
      )
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="予定テンプレートの削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">この予定テンプレートを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default TemplateDelete
