import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Input, Label, Row } from 'reactstrap'

import { Role } from 'api/users/constants'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { showSuccess, showError } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import {
  getUserList,
  getUser,
  getTenantUserList,
  getTenantUser,
  updateTenantUserPermission,
  selectUsersStatus,
  clearErrorMessage,
} from 'slices/usersSlice'

import { NavMenu, List, VerticalTable, Table, CardRadioButton, CardSubmitFooter } from 'components/common'
import type { TableHeaderType, TableCellType, VerticalItem, CardRadioButtonItem } from 'components/common/types'
import { accountTypeName } from 'components/common/utils'

import useAuthority, { TENANT_ADMIN_ID } from 'hooks/useAuthority'
import useUser from 'hooks/useUser'
import type { EditPermissionProps } from 'hooks/useUser'

import UserDelete from './UserDelete'
import UserInvitation from './UserInvitation'

import styles from './Users.module.scss'

const Users: React.FC = () => {
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openInvite, setOpenInvite] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [selectedUserId, setSelectedUserId] = React.useState<string>('')
  const [initEditPermissionData, setInitEditPermissionData] = React.useState<EditPermissionProps | undefined>()

  // sessionSliceのuserとuserSliceのuserで、変数名が重複しないようにsessionUserと名前変更する
  const { user: sessionUser } = useSelector(selectSessionStatus, shallowEqual)
  const {
    userHasTenants: {
      0: { id: tenantId },
    },
  } = sessionUser
  const { allTenantUsers, selectedTenantUsers, user, isRequesting, errorMessage } = useSelector(
    selectUsersStatus,
    shallowEqual
  )

  const { isTenantAdmin, isProcessAdmin } = useAuthority()

  const dispatch = useDispatch()

  const {
    editPermissionData,
    setEditPermissionData,
    disabled,
    bopManagementChangeBoxItems,
    managedWorkspaceCheckBoxItems,
  } = useUser()

  React.useEffect(() => {
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else {
      dispatch(getTenantUserList(tenantId))
    }
  }, [dispatch, isTenantAdmin, tenantId])

  const userHasTenant = React.useMemo(
    () => (isTenantAdmin ? user?.userHasTenants.find(ut => ut.id === TENANT_ADMIN_ID) : user?.userHasTenants[0]),
    [user?.userHasTenants, isTenantAdmin]
  )

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
    if (!user?.userId) {
      return
    }
    isTenantAdmin ? dispatch(getUser(user.userId)) : dispatch(getTenantUser(user.userId))
  }, [submitted, dispatch, isTenantAdmin, isRequesting, errorMessage, user?.userId])

  const listItems = React.useMemo(() => {
    const users = isTenantAdmin
      ? allTenantUsers
          .filter(u => u.partialUserHasTenants.find(tenant => tenant.id === TENANT_ADMIN_ID)?.role === Role.TenantAdmin)
          .map(u => ({ id: u.userId, title: u.partialUserHasTenants[0]?.nickname ?? '' }))
      : selectedTenantUsers
          .filter(u => {
            const target = u.partialUserHasTenants[0]
            return target.role === Role.Admin || target.role === Role.ProcessAdmin
          })
          .map(u => {
            const target = u.partialUserHasTenants[0]
            return {
              id: u.userId,
              title: target.nickname ?? '',
              data: accountTypeName(target?.role),
            }
          })

    if (users.length === 0) {
      return []
    }
    setSelectedUserId(users[0].id)
    return users
  }, [isTenantAdmin, selectedTenantUsers, allTenantUsers])

  React.useEffect(() => {
    if (!selectedUserId) {
      return
    }
    isTenantAdmin ? dispatch(getUser(selectedUserId)) : dispatch(getTenantUser(selectedUserId))
  }, [selectedUserId, isTenantAdmin, dispatch])

  const isReadOnly = React.useMemo(
    () => (editPermissionData ? editPermissionData.role === Role.Admin : true),
    [editPermissionData]
  )

  const updatePermissionData = React.useCallback(() => {
    if (!userHasTenant) {
      return
    }
    const newEditData = {
      role: userHasTenant.role,
      canViewBOP: userHasTenant.canViewBOP,
      canManageBOP: userHasTenant.canManageBOP,
      managedWorkspaces: userHasTenant.managedWorkspaces,
    }

    setEditPermissionData(newEditData)
    setInitEditPermissionData(newEditData)
  }, [setEditPermissionData, userHasTenant])

  React.useEffect(() => {
    if (isTenantAdmin) {
      return
    }

    updatePermissionData()
  }, [isTenantAdmin, updatePermissionData])

  const unchangedUserPermission = React.useMemo(
    () => _.isEqual(initEditPermissionData, editPermissionData),
    [initEditPermissionData, editPermissionData]
  )

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else {
      dispatch(getTenantUserList(tenantId))
    }
  }

  const userInformationItems: VerticalItem[] = React.useMemo(() => {
    const items = [
      { title: '名前', data: userHasTenant?.nickname },
      { title: 'メールアドレス', data: user?.email },
    ]

    return items
  }, [userHasTenant?.nickname, user?.email])

  const accountTypeItems: VerticalItem[] = React.useMemo(() => {
    if (!user) {
      return []
    }

    const items = isProcessAdmin
      ? [
          {
            title: 'アカウントタイプ',
            data: userHasTenant?.role === Role.Admin ? 'オーナー' : 'オペレーター',
          },
        ]
      : []

    return items
  }, [isProcessAdmin, user, userHasTenant?.role])

  const header: TableHeaderType[] = [
    { value: 'テナント ID', width: '15%' },
    { value: '企業名', width: '30%' },
    { value: '事業所名', width: '35%' },
    { value: 'ご利用状況', width: '20%' },
  ]

  const tableItems: TableCellType[][] = React.useMemo(
    () =>
      user?.userHasTenants
        .filter(tenant => tenant.id !== TENANT_ADMIN_ID && tenant.isUserActive)
        .map(tenant => [
          { value: tenant.id },
          { value: tenant.tenantName },
          { value: tenant.salesOfficeName || '-' },
          {
            value: tenant.isTenantActive ? '利用中' : '停止中',
            className: tenant.isTenantActive ? 'text-success' : 'text-danger',
          },
        ]) || [],
    [user?.userHasTenants]
  )

  const navigate = useNavigate()
  const handleRowClick = (index: number) => {
    const targetTenantId = tableItems[index][0].value
    navigate(`/tenants/${targetTenantId}/detail`)
  }

  const radioButtonItems: CardRadioButtonItem[] = [
    {
      value: Role.Admin,
      label: accountTypeName(Role.Admin),
      subtitle: '全てのワークスペースの管理、ユーザーの招待/削除が行える管理者アカウント',
    },
    {
      value: Role.ProcessAdmin,
      label: accountTypeName(Role.ProcessAdmin),
      subtitle: '特定のワークスペースの管理が行える利用者アカウント',
    },
  ]

  const onRadioButtonClick = React.useCallback(
    (value: string) => {
      const isAdmin = value === Role.Admin

      setEditPermissionData(prev => {
        if (!prev) {
          return prev
        }

        const newManagedWorkspaces = prev.managedWorkspaces.map(item => ({
          ...item,
          isManagement: isAdmin,
        }))

        return {
          role: isAdmin ? Role.Admin : Role.ProcessAdmin,
          canViewBOP: isAdmin,
          canManageBOP: isAdmin,
          managedWorkspaces: newManagedWorkspaces,
        }
      })
    },
    [setEditPermissionData]
  )

  const getUpdateUserPermissionData = React.useCallback(() => {
    if (unchangedUserPermission || !initEditPermissionData) {
      return null
    }

    const newPermissionData = _.omitBy(editPermissionData, (value, key) =>
      _.isEqual(value, initEditPermissionData[key as keyof EditPermissionProps])
    ) as EditPermissionProps

    // 管理ワークスペースの権限を更新しない時
    if (!newPermissionData.managedWorkspaces) {
      return newPermissionData
    }

    return {
      ...newPermissionData,
      managedWorkspaces: newPermissionData.managedWorkspaces.map(item => _.pick(item, ['id', 'isManagement'])),
    }
  }, [unchangedUserPermission, editPermissionData, initEditPermissionData])

  const onSubmit = React.useCallback(() => {
    if (!editPermissionData || !user) {
      return
    }

    const updateUserPermissionData = getUpdateUserPermissionData()
    setSubmitted(true)
    dispatch(updateTenantUserPermission(user.userId, updateUserPermissionData!))
  }, [editPermissionData, user, getUpdateUserPermissionData, dispatch])

  const onCancel = React.useCallback(() => {
    // テナント管理者の場合は、キャンセルボタンがないためonCancelは実行されない
    if (isTenantAdmin) {
      return
    }

    updatePermissionData()
  }, [isTenantAdmin, updatePermissionData])

  const onManagedWorkspaceCheckboxChange = React.useCallback(
    (id: number, checked: boolean) => {
      setEditPermissionData(prev => {
        if (!prev) {
          return prev
        }

        const newManagedWorkspaces = prev.managedWorkspaces.map(item =>
          item.id === id ? { ...item, isManagement: checked } : item
        )

        return {
          ...prev,
          managedWorkspaces: newManagedWorkspaces,
        }
      })
    },
    [setEditPermissionData]
  )

  const onBopManagementCheckboxChange = React.useCallback(
    (index: number, checked: boolean) => {
      index === 0
        ? setEditPermissionData(prev => prev && { ...prev, canViewBOP: checked })
        : setEditPermissionData(prev => prev && { ...prev, canManageBOP: checked })
    },
    [setEditPermissionData]
  )

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <div className="font-x-large fw-bold align-self-center">ユーザー一覧</div>
              {!isProcessAdmin && (
                <div className="bg-white rounded">
                  <Button color="primary" className="d-flex align-items-center" onClick={() => setOpenInvite(true)}>
                    <i className="icf-plus pe-2 font-large" />
                    <div className="ps-1">ユーザーの追加</div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Row className={isProcessAdmin ? styles.row : styles.rowNoButton}>
            <Col md={4} className="h-100">
              <Card className={`position-sticky ${styles.list}`}>
                <List items={listItems} selectedId={user?.userId} onAction={setSelectedUserId} />
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                <div className="h-100 overflow-auto">
                  <CardBody>
                    <CardTitle className="font-large fw-bold">ユーザー詳細</CardTitle>
                    <Row className="my-2 mx-0">
                      <Col className="border-top border-end border-start p-0">
                        <VerticalTable items={userInformationItems} titleColumn={3} />
                      </Col>
                    </Row>
                  </CardBody>

                  {!isTenantAdmin && (
                    <>
                      <CardBody>
                        <CardTitle className="font-large fw-bold">アカウントタイプ</CardTitle>
                        {isProcessAdmin ? (
                          <Row className="my-2 mx-0">
                            <Col className="border-top border-end border-start p-0">
                              <VerticalTable items={accountTypeItems} titleColumn={3} />
                            </Col>
                          </Row>
                        ) : (
                          <Card>
                            <CardBody>
                              <CardRadioButton
                                items={radioButtonItems}
                                name="userAuthority"
                                selected={editPermissionData?.role || Role.ProcessAdmin}
                                onClick={onRadioButtonClick}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">管理権限設定</CardTitle>
                        <Card>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">ワークスペース管理</CardTitle>
                            <Row md={2} className="ms-1">
                              {!_.isEmpty(managedWorkspaceCheckBoxItems) &&
                                managedWorkspaceCheckBoxItems.map(item => {
                                  return (
                                    <div className="form-check" key={item.id}>
                                      <Input
                                        className="form-check-input"
                                        id={`${item.id}_checkBox`}
                                        checked={item.checked}
                                        type="checkbox"
                                        onChange={e => onManagedWorkspaceCheckboxChange(item.id, e.target.checked)}
                                        disabled={isProcessAdmin || isReadOnly}
                                      />
                                      <Label className="form-check-label" for={`${item.id}_checkBox`}>
                                        {item.label}
                                      </Label>
                                    </div>
                                  )
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="mt-3">
                          <CardBody>
                            <CardTitle className="font-large fw-bold">収支管理</CardTitle>
                            <Row md={2} className="ms-1">
                              {!_.isEmpty(bopManagementChangeBoxItems) &&
                                bopManagementChangeBoxItems.map((item, index) => {
                                  return (
                                    <div className="form-check" key={item.id}>
                                      <Input
                                        className="form-check-input"
                                        id={`${item.id}_checkBox`}
                                        checked={item.checked}
                                        type="checkbox"
                                        onChange={e => onBopManagementCheckboxChange(index, e.target.checked)}
                                        disabled={isProcessAdmin || isReadOnly}
                                      />
                                      <Label className="form-check-label" for={`${item.id}_checkBox`}>
                                        {item.label}
                                      </Label>
                                    </div>
                                  )
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                      </CardBody>
                    </>
                  )}
                  {isTenantAdmin && tableItems.length > 0 && (
                    <CardBody>
                      <CardTitle className="font-large fw-bold">担当テナント</CardTitle>
                      <Container className="px-0">
                        <Table header={header} body={tableItems} onRowClick={handleRowClick} />
                      </Container>
                    </CardBody>
                  )}

                  {!isProcessAdmin && sessionUser?.userId !== user?.userId && (
                    <CardBody>
                      <CardTitle className="font-large fw-bold">ユーザーアカウント削除</CardTitle>
                      <CardText className="py-2 mb-0">
                        ユーザーアカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                      </CardText>

                      <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                        このユーザーを削除
                      </Button>
                    </CardBody>
                  )}
                </div>

                <CardSubmitFooter
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  submitDisabled={unchangedUserPermission || disabled}
                  cancelDisabled={unchangedUserPermission}
                  updatedBy={user?.userHasTenants[0].updatedByName}
                  updatedAt={user?.userHasTenants[0].updatedAt}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </NavMenu>

      <UserDelete
        isOpen={openDelete}
        tenantId={tenantId}
        userId={user?.userId || ''}
        onSuccess={handleDelete}
        onCancel={() => setOpenDelete(false)}
      />
      <UserInvitation
        isOpen={openInvite}
        onClose={() => setOpenInvite(false)}
        onSuccess={() => dispatch(showSuccess())}
      />
    </>
  )
}

export default Users
