import type { ColorType } from 'components/common/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'
import type { PartialWorkspaceData, WorkspaceData } from './workspaces'

export type GroupData = {
  id: number
  workDate: string
  revision: number
  name: string
  color: ColorType
  updatedAt: string
  updatedByName: string | null
}

export type GroupListResponse = {
  partialTenant: PartialTenantData
  partialWorkspace: PartialWorkspaceData
  workGroups: GroupData[]
}

export type GroupResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
  workGroup: GroupData
}

export const getGroupList = (workspaceId: number): Promise<GroupListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/groups`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getGroup = (workspaceId: number, groupId: number): Promise<GroupResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/groups/${groupId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createGroup = (workspaceId: number, name: string, color: ColorType): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/groups`

    axiosClientWithTenantId
      .post(url, { name, color })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateGroup = (
  workspaceId: number,
  groupId: number,
  name: string,
  color: ColorType
): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/groups/${groupId}`

    axiosClientWithTenantId
      .patch(url, { name, color })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteGroup = (workspaceId: number, groupId: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/groups/${groupId}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
