import { axiosClientWithTenantId } from 'AxiosClientProvider'

export type WorkResultsType = {
  scheduleTypeId: number
  hourlyValue: number[] //1時間ごとに24個の配列、インデックス0は営業開始時間の時
}

export type PutManualInputType = {
  workResults: WorkResultsType[]
}

export type GetManualInputResponse = {
  tenantId: number
  workDate: string // YYYY-MM-DD
  businessStartTime: string
  revision: number
  lastUpdate: string
  lastUpdater: string
  workResults: WorkResultsType[]
}

export type UpdateManualInputResponse = {
  revision: number
}

export type GetAggregateStatusResponse = {
  retryInterval: number
  result: boolean
}

export const getWorkResults = (workDate: string): Promise<GetManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/work-date/${workDate}/work-results`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const postWorkResults = (
  workDate: string,
  workResults: PutManualInputType
): Promise<UpdateManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/work-date/${workDate}/work-results`

    axiosClientWithTenantId
      .post(url, workResults)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAggregateStatus = (workDate: string, revision: number): Promise<GetAggregateStatusResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/work-date/${workDate}/work-results/revision/${revision}/aggregate-status`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const importWorkResults = (workDate: string, workResults: FormData): Promise<UpdateManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/work-date/${workDate}/work-results-with-file`
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    axiosClientWithTenantId
      .post(url, workResults, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
