import { axiosClient, axiosClientWithTenantId } from 'AxiosClientProvider'

import type { CreateResponse, UpdateResponse } from './types'

export type PartialTenantData = {
  id: number
  workDate: string
  revision: number
  name: string
  salesOfficeName: string | null
  personInChargeId: string | null
  personInChargeName: string | null
  isActive: boolean
  updatedAt: string
  updatedByName: string | null
}

export type TenantApplicationSettingType = {
  applicationId: number
  applicationName: string
  options: { apiKey: string; tenant: string; relatedWorkspaceData: { location: string; relatedWorkspaceId: number }[] }
}

export type TenantData = PartialTenantData & {
  postalCode: string | null
  prefecture: string | null
  municipality: string | null
  otherAddress: string | null
  phoneNumber: string | null
  businessStartTime: string
  businessEndTime: string
  locale: string | null
  defaultLanguage: string | null
  optionApplications: TenantApplicationSettingType[]
  workerCount: number
  userCount: number
  createdAt?: string | null
  startedAt?: string | null
  endedAt?: string | null
}

type TenantBaseDataType = {
  workDate?: string
  name: string
  salesOfficeName?: string | null
  postalCode?: string
  prefecture?: string
  municipality?: string
  otherAddress?: string
  phoneNumber?: string
  personInChargeId?: string
  locale?: string
  defaultLanguage?: string
}

export type TenantEditDataType = TenantBaseDataType & {
  businessStartHour: string
  businessStartMinute: string
  businessEndHour: string
  businessEndMinute: string
}

export type TenantResponse = {
  workDate: string
  tenant: TenantData
}

export type TenantListResponse = {
  partialTenants: PartialTenantData[]
}

export type DataConnectionInfoResponse = {
  csvSettings: DataConnectionInfo[]
}

export type DataConnectionInfo = {
  targetDatabase: {
    databaseName: string
    displayName: string
  }
  targetColumns: TargetColumn[]
  filterColumns: FilterColumn[]
}

export type TargetColumn = {
  columnName: string
  displayName: string
}

export type FilterColumn = {
  columnName: string
  displayName: string
  values: string[]
}

const LOCALE = 'ja_JP'
const DEFAULT_LANGUAGE = 'ja'

export const getTenantList = (): Promise<TenantListResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/tenants'

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const createTenant = (data: TenantEditDataType): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = '/tenants'

    axiosClient
      .post(url, convertToEditRequest(data))
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenant = (tenantId: number, date?: string): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}`

    const paramData = date ? { 'work-date': date } : {}
    axiosClient
      .get(url, { params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenant = (tenantId: number, data: TenantEditDataType): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}`

    axiosClient
      .patch(url, convertToEditRequest(data))
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getDataConnectionInfo = (): Promise<DataConnectionInfoResponse> =>
  new Promise((resolve, reject) => {
    const url = '/data-connection-info'

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantStatus = (tenantId: number, isActive: boolean): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/status`

    axiosClient
      .patch(url, { isActive })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantApplication = (
  tenantId: number,
  optionApplications: TenantApplicationSettingType[]
): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/application`

    axiosClient
      .patch(url, { optionApplications })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

/**
 * 画面編集用の営業時間をAPIの形式に変換する。
 */

const convertToEditRequest = (src: TenantEditDataType) => {
  const { businessStartHour, businessStartMinute, businessEndHour, businessEndMinute, ...data } = src
  return {
    ...data,
    businessStartTime: businessStartHour + ':' + businessStartMinute,
    businessEndTime: businessEndHour + ':' + businessEndMinute,
    locale: LOCALE,
    defaultLanguage: DEFAULT_LANGUAGE,
  }
}
