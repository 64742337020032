import type { ColorType, ConnectionType } from 'components/common/types'
import { getUUID } from 'components/common/utils'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { SkillData } from './skills'
import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, DeleteResponse, UpdateResponse } from './types'
import type { PartialWorkspaceData, WorkspaceData } from './workspaces'

export type ScheduleTypeFilterRow = {
  id: string
  key: string
  value: string
}

export type ScheduleTypeFilter = {
  id: string
  data: ScheduleTypeFilterRow[]
}

export type PartialScheduleTypeData = {
  workspaceId: number
  id: number
  workDate: string
  revision: number
  name: string
  color: ColorType
  connectionType: ConnectionType
  dataConnection: boolean
  updatedAt: string
  updatedByName: string | null
  isKey: boolean
  isCostless: boolean
  requiredSkillIds: number[]
  unit: string | null
  defaultProductivity: number | null
}

export type ScheduleTypeData = PartialScheduleTypeData & {
  targetDatabase: string | null
  targetColumn: string | null
  filters: ScheduleTypeFilter[]
}

export type ScheduleTypeListResponse = {
  workDate: string
  partialTenant: PartialTenantData
  partialWorkspace: PartialWorkspaceData
  partialScheduleTypes: PartialScheduleTypeData[]
}

export type ScheduleTypeResponse = {
  tenant: TenantData
  workspace: WorkspaceData
  scheduleType: ScheduleTypeData
}

export type RelatedScheduleTypeListResponse = {
  workDate: string
  partialTenant: PartialTenantData
  partialWorkspaces: PartialWorkspaceData[]
  partialScheduleTypes: PartialScheduleTypeData[]
}

export type OriginalScheduleTypeData = Omit<ScheduleTypeData, 'filters'> & {
  filters: {
    key: string
    value: string
  }[][]
}

export type OriginalScheduleTypeResponse = Omit<ScheduleTypeResponse, 'scheduleType'> & {
  scheduleType: OriginalScheduleTypeData
}

export type ScheduleTypeEditDataType = Omit<
  ScheduleTypeData,
  'workDate' | 'id' | 'revision' | 'updatedByName' | 'updatedAt' | 'workspaceId'
>

export type ScheduleTypeEditData = {
  name: string | undefined
  color: ColorType
  requiredSkills: SkillData[]
  dataConnection: boolean
  connectionType: ConnectionType
  targetDatabase: string | undefined
  targetColumn: string | undefined
  unit: string | undefined
  filters: ScheduleTypeFilter[]
  defaultProductivity?: string
  isKey: boolean
  isCostless: boolean
}

export const getScheduleTypeList = (workspaceId: number, workDate?: string): Promise<ScheduleTypeListResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/schedule-types`

    const paramData = workDate ? { 'work-date': workDate } : {}
    axiosClientWithTenantId
      .get(url, { params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getScheduleType = (workspaceId: number, scheduleTypeId: number): Promise<ScheduleTypeResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(parseResponse(response.data)))
      .catch(error => reject(error))
  })

export const createScheduleType = (workspaceId: number, data: ScheduleTypeEditDataType): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/schedule-types`

    axiosClientWithTenantId
      .post(url, convertToEditRequest(data))
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateScheduleType = (
  workspaceId: number,
  scheduleTypeId: number,
  data: ScheduleTypeEditDataType
): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

    axiosClientWithTenantId
      .patch(url, convertToEditRequest(data))
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteScheduleType = (workspaceId: number, scheduleTypeId: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getRelatedScheduleType = (workDate?: string): Promise<RelatedScheduleTypeListResponse> =>
  new Promise((resolve, reject) => {
    const url = '/related-schedule-types'

    const paramData = workDate ? { 'work-date': workDate } : {}
    axiosClientWithTenantId
      .get(url, { params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

/**
 * ScheduleTypeResponseのfilterにidを加えて画面側で扱い形式に変換する。
 */
const parseResponse = (response: OriginalScheduleTypeResponse) => {
  const filters = response.scheduleType.filters
    .filter(parent => parent.length > 0)
    .map(parent => {
      const data = parent.map(child => ({
        id: getUUID(),
        key: child.key,
        value: child.value,
      }))
      return {
        id: getUUID(),
        data,
      }
    })
  const scheduleTypeWithFilter = {
    ...response.scheduleType,
    filters,
  }

  return { ...response, scheduleType: scheduleTypeWithFilter }
}

/**
 * 画面側で加えたidを除外してAPI用の値に変換する。
 */
const convertToEditRequest = (src: ScheduleTypeEditDataType) => {
  const filters = src.filters.map(parent => parent.data.map(row => ({ key: row.key, value: row.value })))
  return { ...src, filters: filters.length > 0 ? filters : [[]] }
}
