import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { chain, debounce, findIndex, groupBy, isEmpty, isEqual, keys, sortBy, sumBy } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, ButtonGroup } from 'reactstrap'

import { PlanUpdateModeTypes } from 'api/plans'
import type { WorkersPlanData, UpdatePlanSchedule } from 'api/plans'
import { Role } from 'api/users/constants'

import { getScheduleTypeSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { showError, showSuccess, showWarning } from 'slices/notificationSlice'
import {
  getPlanByDate,
  getPlanByDateWithShiftCheck,
  getPlanList,
  updateProductivityPlanAndPlanBulkCreate,
  getProductivityPlan,
  selectPlansStatus,
} from 'slices/plansSlice'
import { getScheduleTypeList, getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getSkillList } from 'slices/skillsSlice'
import { getTenantWithDate, selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import ImportOptimizedDialog from 'components/Schedules/Optimization/ImportOptimizedDialog'
import { OptimizationDialog, GROUP_BLANK_ID } from 'components/Schedules/Optimization/OptimizationDialog'
import TargetValuesUpdate from 'components/Schedules/TargetValuesUpdate'
import ImportTemplateDialog from 'components/Workspaces/Template/ImportTemplateDialog'
import {
  BadgeLabel,
  NavMenu,
  SubmitFooter,
  MoveDropdown,
  AssignedNumberTable,
  IconButton,
  SidebarButton,
  DateChangeButton,
  DropdownButton,
} from 'components/common'
import type { ColorType, EditSchedule, TableItemType, ItemScheduleType } from 'components/common/types'
import {
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  MAGIQANNEAL_APPLICATION_ID,
  hasOverlappedSchedule,
  UNSELECTED_SCHEDULE_TYPE_ID,
  timeOverlapped,
  getRandomNumber,
} from 'components/common/utils'

import useAuthority from 'hooks/useAuthority'
import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'
import useTargetsImport from 'hooks/useTargetsImport'

import ImportTargetsDialog from '../ImportTargetsDialog'
import PerformanceRates from '../PerformanceRates/PerformanceRates'

import AssignToWorkTable from './AssignToWorkTable'
import DeleteSchedules from './DeleteSchedules'
import ShiftAdjustment from './ShiftAdjustment'
import WorkPlanCard from './WorkPlanCard'
import WorkPlanFilter from './WorkPlanFilter'
import { AssignToWorkTableContext } from './context'

import styles from './WorkPlan.module.scss'

import type { ScheduleTypeGroup } from './ScheduleTypeSelector'
import type { CompareGraph, GraphRow } from './WorkPlanCard'
import type { SelectedScheduleType } from './context'
import type { DisplayPerformanceRate, PerformanceRateData } from '../PerformanceRates/PerformanceRates'
import type { WorkPlanSchedulesType, EditGroupsWorkerType, EditGroupsType } from '../types'

dayjs.extend(isBetween)

const hasUnselectedSchedule = (schedules: EditSchedule[]) =>
  schedules.some(schedule => schedule.scheduleTypeId === UNSELECTED_SCHEDULE_TYPE_ID)
type WorkPlanCardItem = {
  scheduleTypeId: number
  label: string
  color: ColorType
  unit: string | null
  targetValue: number
  planValue: number
  graphRows: GraphRow[]
  defaultProductivity: number
}

type SeparateScheduleType = { scheduleTypeId: number; time: string; planValue: number }

const separateScheduleByQuarterHours = (start: string, duration: number) => {
  const startMoment = moment(start)
  return [...Array(Math.round(duration / 900))].map((_value, i) => ({
    time: startMoment
      .clone()
      .add(900 * i, 'seconds')
      .toISOString(),
    duration: 900,
  }))
}

const calcPlanValue = (
  performanceRate: { time: string; value: number | null }[],
  performanceIndex: number,
  startAt: string,
  duration: number
) => {
  const calcScheduleByQuarterHours = separateScheduleByQuarterHours(startAt, duration).map(separateSchedule => {
    const targetPerformanceRate = performanceRate.find(pr => pr.time === separateSchedule.time)?.value ?? 1
    const planValue = (separateSchedule.duration * performanceIndex * targetPerformanceRate) / 3600 // durationから予測値を計算
    return {
      time: separateSchedule.time,
      planValue,
    }
  })
  const groupByHours = groupBy(calcScheduleByQuarterHours, o => new Date(o.time).getHours())
  return keys(groupByHours).map(hours => ({
    time: new Date(new Date(groupByHours[hours][0].time).setMinutes(0)).toISOString(),
    planValue: sumBy(groupByHours[hours], 'planValue'),
  }))
}

// 生産性調整の時間が重なっていないかチェックする
const hasOverlappedPerformanceRate = (data: PerformanceRateData[]) => {
  return data.some((controlData, index) => {
    return data.slice(index + 1).some(targetData => {
      return timeOverlapped(controlData.startTime, controlData.duration, targetData.startTime, targetData.duration)
    })
  })
}

const WorkPlan: React.FC = () => {
  const params = useParams<'workspaceId' | 'workDate'>()
  const { workspaceId, workDate } = React.useMemo(
    () => ({
      workspaceId: Number(params.workspaceId),
      workDate: String(params.workDate),
    }),
    [params]
  )
  const [openImportOptimized, setOpenImportOptimized] = React.useState(false)
  const [openOptimization, setOpenOptimization] = React.useState(false)
  const [openTargetValuesUpdate, setOpenTargetValuesUpdate] = React.useState(false)
  const [openDeleteSchedules, setOpenDeleteSchedules] = React.useState(false)
  const [openTemplate, setOpenTemplate] = React.useState(false)
  const [openShiftAdjustment, setOpenShiftAdjustment] = React.useState(false)
  const [initGroups, setInitGroups] = React.useState<EditGroupsType[]>([])
  const [editGroups, setEditGroups] = React.useState<EditGroupsType[]>([])
  const [selectedWorker, setSelectedWorker] = React.useState<number[]>([])
  const [displayRate, setDisplayRate] = React.useState<DisplayPerformanceRate[]>([])
  const [initDisplayRate, setInitDisplayRate] = React.useState<DisplayPerformanceRate[]>([])
  const [shouldResetFilters, setShouldResetFilters] = React.useState(false)
  // 再レンダリングにより、保存中の画面から変更したスケジュールが変更前の状態で表示されるためuseStateではなくuseRefを使う
  const separateSchedulesBeforeSaving = React.useRef<SeparateScheduleType[]>([])
  const [submitted, setSubmitted] = React.useState(false)
  const [viewWorkTable, setViewWorkTable] = React.useState<
    'workTable' | 'performanceRateTable' | 'assignedNumberTable'
  >('workTable')

  const [workTablePosition, setWorkTablePosition] = React.useState<{ top?: number; left?: number }>({})
  const [numberTablePosition, setNumberTablePosition] = React.useState<{ top?: number; left?: number }>({})
  const [performanceRatesTablePosition, setPerformanceRatesTablePosition] = React.useState<{
    top?: number
    left?: number
  }>({})
  const [shiftKeyDown, setShiftKeyDown] = React.useState(false)
  const [selectedSchedules, setSelectedSchedules] = React.useState<SelectedScheduleType[]>([])
  const [isOpenSidebar, setOpenSidebar] = React.useState(true)
  const [isOpenFilter, setIsOpenFilter] = React.useState(false)
  const [isImportTargetsDialogOpen, setIsImportTargetsDialogOpen] = React.useState(false)
  window.document.onkeydown = event => setShiftKeyDown(event.shiftKey)
  window.document.onkeyup = event => setShiftKeyDown(event.shiftKey)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { scheduleTypeSummary } = useSelector(selectDashboardStatus, shallowEqual)
  const { partialScheduleTypes, allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { isRequesting, errorMessage, plans, productivityAdjustments, scheduleTruncated } = useSelector(
    selectPlansStatus,
    shallowEqual
  )
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { businessStartTime, businessEndTime, businessDuration, getWorkDate } = useBusinessTime(tenantWithDate)

  const { exportTargets } = useTargetsImport()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
    dispatch(getTenantWithDate())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getRelatedScheduleType(workDate))
  }, [dispatch, workDate])

  React.useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId, workDate))
  }, [dispatch, workspaceId, workDate])

  React.useEffect(() => {
    dispatch(getPlanList(workspaceId, workDate, workDate))
    dispatch(getPlanByDate(workspaceId, workDate))
    dispatch(getProductivityPlan(workspaceId, workDate))
  }, [dispatch, workspaceId, workDate])

  const {
    planStartDateTime,
    planScheduleTypes,
    dailyTarget,
    planLastUpdatedAt,
    planLastUpdater,
    getEditSchedulesFromWorkPlan,
    getWorkerPlanFromUpdatePlanSchedule,
  } = usePlans(tenantWithDate)

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  const { apiKey, magiQannealTenant, magiQannealLocation, isOptimization } = React.useMemo(() => {
    const application = plans?.tenant.optionApplications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
    const targetOptimization = application?.options.relatedWorkspaceData?.find(
      rw => rw.relatedWorkspaceId === workspaceId
    )
    return {
      apiKey: application?.options.apiKey ?? '',
      magiQannealTenant: application?.options.tenant ?? '',
      magiQannealLocation: targetOptimization?.location ?? '',
      isOptimization: !!application && !!targetOptimization,
    }
  }, [plans, workspaceId])

  const optimizationItems = React.useMemo(() => {
    return [
      {
        label: '配置結果を表示',
        onClick: () => {
          const server = process.env.REACT_APP_OPTIMIZATION_SERVER
          const datetime = encodeURIComponent(dayjs(workDate).format())
          window.open(`${server}/${magiQannealTenant}/${magiQannealLocation}/board/?datetime=${datetime}`, '_blank')
        },
      },
      { label: '配置結果を作業計画に取込', onClick: () => setOpenImportOptimized(true) },
    ]
  }, [magiQannealTenant, magiQannealLocation, workDate])

  const correctionBusinessTime = React.useMemo(() => {
    const round = moment(businessEndTime, 'HH:mm').minutes() > 0 ? 1 : 0
    const start = `${businessStartTime.slice(0, 2)}:00`
    // 24:00 が 00:00 にならないように number で計算する
    const end = `${Number(businessEndTime.slice(0, 2)) + round}:00`
    return {
      start,
      end,
      duration: (moment(end, 'HH:mm').unix() - moment(start, 'HH:mm').unix()) / 900,
    }
  }, [businessEndTime, businessStartTime])

  const workspace = React.useMemo(() => plans?.workspace, [plans])
  const isPast = React.useMemo(
    () =>
      !!plans &&
      dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')), 'YYYY-MM-DD').isAfter(getWorkDate(plans.workDate), 'day'),
    [getWorkDate, plans]
  )

  const separateSchedules = React.useMemo(() => {
    // scheduleの開始時間とdurationを1時間単位に分解し、予測値を計算する
    const calculatedSeparateSchedule = editGroups
      .flatMap(g => g.workers)
      .flatMap(worker =>
        worker.schedules
          .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID) // シフトを除く
          .filter(s => planScheduleTypes.find(scheduleType => scheduleType.id === s.scheduleTypeId)?.dataConnection) // 実績がないものをを除く
          .map(schedule => ({ schedule: schedule, performanceIndices: worker.partialHourlyProductivities }))
      )
      .map(({ schedule, performanceIndices }) => {
        const defaultProductivity =
          partialScheduleTypes.find(type => type.id === schedule.scheduleTypeId)?.defaultProductivity ?? 0

        const performanceIndex =
          performanceIndices.find(pi => pi.scheduleTypeId === schedule.scheduleTypeId)?.value ?? defaultProductivity

        const performanceRate =
          displayRate
            .find(pr => pr.scheduleTypeId === schedule.scheduleTypeId)
            ?.data.map(data =>
              separateScheduleByQuarterHours(data.startTime, data.duration).map(s => ({
                // 生産性調整の計算のため、15分ごとに分解
                value: data.performanceRateValue,
                time: s.time,
              }))
            )
            .flatMap(data => data) || []

        // 生産性調整、人時生産性を含んだ予測値を計算
        return calcPlanValue(performanceRate, performanceIndex, schedule.startAt, schedule.duration).map(data => ({
          ...data,
          scheduleTypeId: schedule.scheduleTypeId,
        }))
      })
      .flatMap(schedules => schedules)
    const groupByScheduleTypeId = groupBy(calculatedSeparateSchedule, 'scheduleTypeId')

    // 同じ時間かつ同じscheduleTypeIdの予測値を合計する
    return keys(groupByScheduleTypeId)
      .map(scheduleTypeId => {
        const groupByTime = groupBy(groupByScheduleTypeId[scheduleTypeId], 'time')
        return keys(groupByTime).map(time => ({
          scheduleTypeId: Number(scheduleTypeId),
          time,
          planValue: sumBy(groupByTime[time], 'planValue'), // 同じ時間の予測値を合計する
        }))
      })
      .flatMap(data => data)
  }, [editGroups, planScheduleTypes, partialScheduleTypes, displayRate])

  const createGraphRows = React.useCallback(
    (scheduleTypeId: number) => {
      const target = scheduleTypeSummary.find(summary => summary.scheduleTypeId === scheduleTypeId)
      if (!target) {
        return []
      }

      // 表示中のワークスペース以外の作業を選択しているか
      const isOtherWorkspace = !separateSchedules.some(s => s.scheduleTypeId === scheduleTypeId)

      const scheduleTypeSummaryTimes = scheduleTypeSummary
        .filter(s => s.scheduleTypeId === scheduleTypeId)
        .flatMap(s => s.hourlyWorkerData)
        .map(d => new Date(d.time))

      const existingTimes = isOtherWorkspace
        ? scheduleTypeSummaryTimes
        : Array.from(new Set(separateSchedules.map(s => new Date(s.time)).concat(scheduleTypeSummaryTimes)))

      return existingTimes.map(time => {
        // 日付のフォーマットが異なるため、Date型に変換して比較する
        const targetSummary = target.hourlyWorkerData.find(
          hourlyData => new Date(hourlyData.time).getTime() === time.getTime()
        )

        const planValue = isOtherWorkspace
          ? targetSummary?.planCount || null
          : separateSchedules.find(
              s => s.scheduleTypeId === scheduleTypeId && new Date(s.time).getTime() === time.getTime()
            )?.planValue || null
        const actualValue = targetSummary?.recordCount || null
        return {
          time: time.toString(),
          planValue,
          actualValue,
        }
      })
    },

    [scheduleTypeSummary, separateSchedules]
  )

  const workPlanCardItems = React.useMemo(() => {
    if (!plans || isEmpty(dailyTarget)) {
      return []
    }

    return planScheduleTypes.reduce((acc: WorkPlanCardItem[], cur) => {
      const graphRows = createGraphRows(cur.id)
      const targetValue = dailyTarget.data.find(t => t.scheduleTypeId === cur.id)?.target ?? 0
      const planValue = sumBy(
        separateSchedules.filter(schedule => schedule.scheduleTypeId === cur.id),
        'planValue'
      )
      if (cur.dataConnection) {
        acc.push({
          scheduleTypeId: cur.id,
          label: cur.name,
          color: cur.color,
          unit: cur.unit ?? '',
          targetValue,
          planValue,
          graphRows,
          defaultProductivity: cur.defaultProductivity ?? 0,
        })
      }
      return acc
    }, [])
  }, [plans, planScheduleTypes, createGraphRows, dailyTarget, separateSchedules])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    if (errorMessage === '') {
      if (scheduleTruncated) {
        dispatch(
          showWarning({
            warningMessage:
              '正常に処理されました。シフト情報が更新されたため、作業計画に変更が加えられている場合があります。',
          })
        )
      } else {
        dispatch(showSuccess())
      }

      dispatch(getPlanList(workspaceId, workDate, workDate))
      dispatch(getPlanByDate(workspaceId, workDate))
      dispatch(getProductivityPlan(workspaceId, workDate))
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, scheduleTruncated, workspaceId, workDate])

  const scheduleTypeGroups = React.useMemo(() => {
    if (isEmpty(allScheduleTypes)) {
      return []
    }

    const groupedScheduleTypeList = groupBy(allScheduleTypes, 'workspaceId')
    const workspaceIds: string[] = Object.keys(groupedScheduleTypeList)

    return chain(workspaceIds)
      .map<ScheduleTypeGroup>(wid => {
        const name = groupedScheduleTypeList[wid][0].workspaceName
        const scheduleTypeItems = groupedScheduleTypeList[wid]
          .filter(scheduleType => scheduleType.dataConnection)
          .map(scheduleType => ({
            scheduleTypeId: scheduleType.id,
            scheduleTypeName: scheduleType.name,
            scheduleTypeColor: scheduleType.color,
          }))

        return {
          workspaceId: Number(wid),
          workspaceName: name,
          items: scheduleTypeItems,
        }
      })
      .filter(group => group.items.length > 0)
      .sortBy('workspaceName')
      .value()
  }, [allScheduleTypes])

  const compareGraphs = React.useMemo(() => {
    if (isEmpty(scheduleTypeSummary)) {
      return []
    }

    if (isEmpty(allScheduleTypes)) {
      return partialScheduleTypes.map<CompareGraph>(data => {
        const graphRows = createGraphRows(data.id)
        return {
          scheduleTypeId: data.id,
          scheduleTypeName: data.name,
          scheduleTypeColor: data.color,
          unit: data.unit,
          workspaceName: workspace?.name || '',
          graphRows,
        }
      })
    }

    return allScheduleTypes.map<CompareGraph>(data => {
      const graphRows = createGraphRows(data.id)
      const targetWorkspace = partialWorkspaces.find(w => w.id === data.workspaceId)
      return {
        scheduleTypeId: data.id,
        scheduleTypeName: data.name,
        scheduleTypeColor: data.color,
        unit: data.unit,
        workspaceName: targetWorkspace?.name || '',
        graphRows,
      }
    })
  }, [scheduleTypeSummary, allScheduleTypes, partialScheduleTypes, createGraphRows, workspace?.name, partialWorkspaces])

  const createEditGroupWorkers = React.useCallback(
    (workersPlan: WorkersPlanData[], editWorkers: EditGroupsWorkerType[], isSupport: boolean) => {
      return workersPlan.map(worker => {
        const schedules: EditSchedule[] = getEditSchedulesFromWorkPlan(worker, isSupport)
        const visible = editWorkers.find(editWorker => editWorker.workerId === worker.workerId)?.visible ?? true

        return {
          workerId: worker.workerId,
          skillIds: worker.skillIds,
          wmsMemberId: worker.wmsMemberId,
          workerType: worker.workerType,
          name: worker.workerName,
          schedules: schedules.map(s => ({ ...s, editable: true })),
          partialHourlyProductivities: worker.partialHourlyProductivities,
          visible,
        }
      })
    },
    [getEditSchedulesFromWorkPlan]
  )

  React.useEffect(() => {
    if (!plans) {
      return
    }

    // depsにeditGroupsを追加しないために、setEditGroups内の関数で記載する
    setEditGroups(prev => {
      const editWorkers = prev.flatMap(g => g.workers)
      const groups: EditGroupsType[] = plans.groups
        .filter(g => g.workersPlan.some(w => w.workShifts.some(s => s && s > 0)))
        .map(g => {
          const targetWorkersPlan = g.workersPlan.filter(w => w.workShifts.some(s => s && s > 0))
          const isSupport = g.isSupported

          return {
            groupId: g.groupId ?? GROUP_BLANK_ID,
            name: g.groupName ?? '未所属',
            supportedWorkspaceId: isSupport ? getRandomNumber() : null,
            supportedWorkspaceName: isSupport ? g.groupName : null,
            workers: createEditGroupWorkers(targetWorkersPlan, editWorkers, isSupport),
          }
        })
      setInitGroups(groups)
      return groups
    })
  }, [createEditGroupWorkers, plans, partialWorkspaces])

  const workersPlanForShiftAdjustment = React.useMemo(
    () =>
      plans?.groups
        .flatMap(g => g.workersPlan)
        .map(w => ({ workerId: w.workerId, workShift: w.workShifts, workType: w.workScheduleTypes })) || [],

    [plans]
  )

  const onSuccessShiftAdjustment = () => {
    setOpenShiftAdjustment(false)
    dispatch(showSuccess())
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getPlanByDate(workspaceId, workDate))
  }

  const onErrorShiftAdjustment = () => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getPlanByDate(workspaceId, workDate))
  }

  const onTargetValuesUpdateSuccess = () => {
    setOpenTargetValuesUpdate(false)
    dispatch(showSuccess())
    dispatch(getPlanList(workspaceId, workDate, workDate))
  }

  const unchanged = React.useMemo(
    () => isEqual(initGroups, editGroups) && isEqual(initDisplayRate, displayRate),
    [initGroups, editGroups, initDisplayRate, displayRate]
  )

  const disabled = React.useMemo(() => {
    if (user.userHasTenants[0].role === Role.ProcessAdmin && !workspace?.memberIds.includes(user.userId)) {
      return true
    }
    // 作業(Schedule)が重なった状態のときは保存ボタンを無効にする
    const isOverlappedSchedule = editGroups.some(editGroup => {
      return (
        editGroup.workers.some(worker => hasUnselectedSchedule(worker.schedules)) ||
        editGroup.workers.some(worker => hasOverlappedSchedule(worker.schedules, false))
      )
    })
    const isOverlappedPerformanceRate = displayRate.some(performanceRate =>
      hasOverlappedPerformanceRate(performanceRate.data)
    )
    return isOverlappedSchedule || isOverlappedPerformanceRate
  }, [user, workspace, editGroups, displayRate])

  const onCancel = () => {
    setSelectedWorker([])
    setSelectedSchedules([])
    setEditGroups(initGroups)
    setDisplayRate(initDisplayRate)
    setShouldResetFilters(true)
  }

  const createDisplayPerformanceRatesData = React.useCallback(
    (scheduleTypeId: number) => {
      const targetProductivity = productivityAdjustments?.find(p => p.scheduleTypeId === scheduleTypeId)?.values

      if (!targetProductivity) {
        return []
      }

      const performanceRateData: PerformanceRateData[] = []

      for (let i = 0; i < targetProductivity.length; i += 3) {
        if (targetProductivity[i]) {
          const productivityPlanValue = targetProductivity[i]
          const startIndex = i
          const startAt = new Date(planStartDateTime.getTime())
          const elapsedMin = i * 5
          startAt.setMinutes(startAt.getMinutes() + elapsedMin)

          while (productivityPlanValue === targetProductivity[i + 3]) {
            if (i + 3 < targetProductivity.length) {
              i += 3
            }
          }
          const endIndex = i + 2

          performanceRateData.push({
            performanceRateId: Math.random(),
            startTime: startAt.toISOString(),
            duration: (endIndex - startIndex + 1) * 300,
            performanceRateValue: productivityPlanValue,
          })
        }
      }

      return performanceRateData
    },
    [planStartDateTime, productivityAdjustments]
  )

  React.useEffect(() => {
    if (!planScheduleTypes) {
      return
    }
    const data = planScheduleTypes
      .filter(s => s.dataConnection)
      .map(s => ({
        name: s.name,
        scheduleTypeId: s.id,
        data: createDisplayPerformanceRatesData(s.id),
      }))

    setDisplayRate(data)
    setInitDisplayRate(data)
  }, [planScheduleTypes, createDisplayPerformanceRatesData])

  const createUpdateProductivityPlanData = React.useCallback(() => {
    const newProductivityPlan = displayRate.map(targetRate => {
      const newProductivity = targetRate.data.reduce((acc: (number | null)[], cur): (number | null)[] => {
        const startAt = cur.startTime
        const duration = cur.duration / 300 // 300秒(5分)区切りに計算

        const startDateTime = new Date(startAt)
        const diffTimeMin = (startDateTime.getTime() - planStartDateTime.getTime()) / (60 * 1000) // 差分を分単位にする
        const startIndex = diffTimeMin / 5 // 5分区切りに計算
        const endIndex = startIndex + duration

        for (let i = startIndex; i < endIndex; i++) {
          acc[i] = cur.performanceRateValue
        }

        return acc
      }, new Array<number | null>(288).fill(0))

      return { scheduleTypeId: targetRate.scheduleTypeId, productivityAdjustment: { values: newProductivity } }
    })

    return newProductivityPlan
  }, [displayRate, planStartDateTime])

  const onSubmit = useCallback(() => {
    setSelectedWorker([])
    setSelectedSchedules([])
    const differenceWorkerPlans = editGroups.reduce((acc: UpdatePlanSchedule[], cur) => {
      // 変更があるグループを抽出
      const targetInitGroup = initGroups.find(
        ig =>
          (ig.supportedWorkspaceId
            ? ig.supportedWorkspaceId === cur.supportedWorkspaceId
            : ig.groupId === cur.groupId) && !isEqual(ig, cur)
      )
      if (!targetInitGroup) {
        return acc
      }
      const updateSchedules = cur.workers
        .filter(
          w =>
            !isEqual(
              w,
              targetInitGroup.workers.find(iw => iw.workerId === w.workerId)
            )
        )
        .flatMap(w =>
          w.schedules.map(s => ({
            scheduleId: !s.scheduleId || s.scheduleId < 1 ? null : s.scheduleId,
            schedule: {
              scheduleTypeId: s.scheduleTypeId,
              supportWorkspaceId: s.supportWorkspaceId,
              startAt: s.startAt,
              duration: s.duration,
              workerId: w.workerId,
              groupId: null,
            },
            isSupport: cur.supportedWorkspaceName !== null,
          }))
        )
      return acc.concat(...updateSchedules)
    }, [])
    const updatePlanData = isEqual(initGroups, editGroups)
      ? undefined
      : {
          updateMode: PlanUpdateModeTypes.WorkPlan,
          workersPlan: getWorkerPlanFromUpdatePlanSchedule(differenceWorkerPlans),
        }

    const performanceRatesData = isEqual(initDisplayRate, displayRate) ? undefined : createUpdateProductivityPlanData()

    separateSchedulesBeforeSaving.current = separateSchedules
    setSubmitted(true)
    setShouldResetFilters(true)
    dispatch(updateProductivityPlanAndPlanBulkCreate(workspaceId, workDate!, updatePlanData, performanceRatesData))
  }, [
    editGroups,
    initGroups,
    getWorkerPlanFromUpdatePlanSchedule,
    initDisplayRate,
    displayRate,
    createUpdateProductivityPlanData,
    separateSchedules,
    dispatch,
    workspaceId,
    workDate,
  ])

  const onOpenWorkPlanCard = React.useCallback(
    (scheduleTypeId: number) => {
      if (!scheduleTypeSummary.some(summary => summary.scheduleTypeId === scheduleTypeId)) {
        dispatch(getScheduleTypeSummary(workspaceId, workDate, scheduleTypeId))
      }
    },
    [dispatch, scheduleTypeSummary, workspaceId, workDate]
  )

  const onSelectCompareScheduleTypeId = React.useCallback(
    (scheduleTypeId: number) => {
      if (!scheduleTypeSummary.some(summary => summary.scheduleTypeId === scheduleTypeId)) {
        dispatch(getScheduleTypeSummary(workspaceId, workDate, scheduleTypeId))
      }
    },
    [dispatch, scheduleTypeSummary, workspaceId, workDate]
  )

  const assignedTableItems = React.useMemo((): TableItemType[] => {
    // editGroups から schedule 部分だけを抽出する
    const schedules = editGroups.reduce((acc: WorkPlanSchedulesType[], cur) => {
      const workerSchedules = cur.workers.map(worker => worker.schedules)
      return acc.concat(...workerSchedules)
    }, [])

    const base: ItemScheduleType[] = partialScheduleTypes.map(s => ({
      id: s.id,
      name: s.name,
      color: s.color,
      counts: new Map(),
    }))

    if (schedules.length === 0) {
      return [{ id: workspaceId, name: workspace?.name || '', counts: new Map(), schedules: base }]
    }

    const splitTime = businessStartTime.split(':')
    const list: ItemScheduleType[] = [...Array(businessDuration)].reduce((baseAcc, cur, index) => {
      const workStartTime = dayjs(workDate)
        .hour(Number(splitTime[0]))
        .minute(Number(splitTime[1]))
        .add(index * 15, 'minute')
      const itemSchedules = schedules.reduce<ItemScheduleType[]>((acc, schedule) => {
        if (
          !schedule.scheduleTypeId ||
          schedule.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ||
          schedule.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID
        ) {
          return acc
        }
        const scheduleStart = dayjs(schedule.startAt)
        const scheduleEnd = dayjs(schedule.startAt).add(schedule.duration, 'second')
        if (workStartTime.isBetween(scheduleStart, scheduleEnd, 'minute', '[)')) {
          const target = findIndex(acc, { id: schedule.scheduleTypeId })
          if (target > -1) {
            // 24:00以降(workDateが日付が一致しない)場合はhourの値に24を足す
            const targetHour = workStartTime.startOf('day').isSame(dayjs(workDate).startOf('day'), 'days')
              ? workStartTime.hour()
              : workStartTime.hour() + 24
            const hour = acc[target].counts.get(targetHour) || 0
            acc[target].counts.set(targetHour, hour + 0.25)
          }
        }
        return acc
      }, baseAcc)

      return itemSchedules
    }, base)

    const counts: Map<number, number> = list.reduce((acc, cur) => {
      Array.from(cur.counts.entries()).forEach(([key, value]) => acc.set(key, (acc.get(key) || 0) + value))
      return acc
    }, new Map<number, number>())

    return [{ id: workspaceId, name: workspace?.name || '', counts, schedules: list }]
  }, [businessDuration, businessStartTime, editGroups, partialScheduleTypes, workDate, workspace?.name, workspaceId])

  // スクロール位置の復元
  const workTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = workTableElement.current
    if (current && (current.scrollTop !== workTablePosition.top || current.scrollLeft !== workTablePosition.left)) {
      workTableElement.current?.scrollTo({
        behavior: 'auto',
        top: workTablePosition.top || 0,
        left: workTablePosition.left,
      })
    }
  }, [viewWorkTable, workTablePosition])

  const handleWorkTableScroll = React.useMemo(
    () =>
      debounce(
        () =>
          setWorkTablePosition({
            top: workTableElement.current?.scrollTop,
            left: workTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setWorkTablePosition]
  )

  const numberTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = numberTableElement.current
    if (current && (current.scrollTop !== numberTablePosition.top || current.scrollLeft !== numberTablePosition.left)) {
      numberTableElement.current?.scrollTo({
        behavior: 'auto',
        top: numberTablePosition.top || 0,
        left: numberTablePosition.left,
      })
    }
  }, [viewWorkTable, numberTablePosition])

  const handleNumberTableScroll = React.useMemo(
    () =>
      debounce(
        () =>
          setNumberTablePosition({
            top: numberTableElement.current?.scrollTop,
            left: numberTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setNumberTablePosition]
  )

  const performanceRatesTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = performanceRatesTableElement.current
    if (
      current &&
      (current.scrollTop !== performanceRatesTablePosition.top ||
        current.scrollLeft !== performanceRatesTablePosition.left)
    ) {
      performanceRatesTableElement.current?.scrollTo({
        behavior: 'auto',
        top: performanceRatesTablePosition.top || 0,
        left: performanceRatesTablePosition.left,
      })
    }
  }, [viewWorkTable, performanceRatesTablePosition])

  const handlePerformanceRatesTableScroll = React.useMemo(
    () =>
      debounce(
        () =>
          setPerformanceRatesTablePosition({
            top: performanceRatesTableElement.current?.scrollTop,
            left: performanceRatesTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setPerformanceRatesTablePosition]
  )

  const tableView = React.useMemo(() => {
    if (!plans) {
      return <></>
    }
    switch (viewWorkTable) {
      case 'workTable':
        return (
          <AssignToWorkTableContext.Provider value={{ shiftKeyDown, selectedSchedules, setSelectedSchedules }}>
            <AssignToWorkTable
              workspaceId={workspaceId}
              date={plans.workDate}
              isPast={isPast}
              editGroups={editGroups}
              setEditGroups={setEditGroups}
              selectedWorker={selectedWorker}
              setSelectedWorker={setSelectedWorker}
              divElement={workTableElement}
              onScroll={handleWorkTableScroll}
              className={isOpenFilter ? styles.openFilterTableHeight : styles.closeFilterTableHeight}
              tenantWithDate={tenantWithDate}
            ></AssignToWorkTable>
          </AssignToWorkTableContext.Provider>
        )
      case 'assignedNumberTable':
        return (
          <AssignedNumberTable
            businessStartTime={correctionBusinessTime.start}
            businessEndTime={correctionBusinessTime.end}
            items={assignedTableItems}
            divElement={numberTableElement}
            onScroll={handleNumberTableScroll}
            tenantWithDate={tenantWithDate}
          ></AssignedNumberTable>
        )
      case 'performanceRateTable':
        return (
          <PerformanceRates
            date={plans.workDate}
            isPast={isPast}
            displayRateData={displayRate}
            setDisplayRateData={setDisplayRate}
            divElement={performanceRatesTableElement}
            onScroll={handlePerformanceRatesTableScroll}
            tenantWithDate={tenantWithDate}
          />
        )
      default:
        return <></>
    }
  }, [
    tenantWithDate,
    viewWorkTable,
    assignedTableItems,
    correctionBusinessTime,
    editGroups,
    handleNumberTableScroll,
    handleWorkTableScroll,
    handlePerformanceRatesTableScroll,
    isPast,
    selectedSchedules,
    selectedWorker,
    shiftKeyDown,
    workspaceId,
    displayRate,
    isOpenFilter,
    plans,
  ])

  const handleImportTargetsSuccess = React.useCallback(() => {
    setIsImportTargetsDialogOpen(false)
    dispatch(getPlanList(workspaceId, workDate, workDate))
    dispatch(showSuccess())
  }, [dispatch, workDate, workspaceId])

  const handleSuccess = useCallback(() => {
    if (!plans?.workDate) {
      return
    }
    navigate(`/schedules/${workspaceId}/${plans.workDate}`)
    dispatch(getTenantWithDate(plans.workDate))
  }, [plans?.workDate, navigate, dispatch, workspaceId])

  return (
    <>
      <NavMenu isOpenSidebar={isOpenSidebar}>
        <>
          <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex">
                <DateChangeButton
                  date={workDate || moment().format('YYYY-MM-DD')}
                  onChange={date => {
                    dispatch(getPlanByDateWithShiftCheck(workspaceId, moment(date).format('YYYY-MM-DD')))
                  }}
                  onSuccess={handleSuccess}
                  onError={() => dispatch(showError({ errorMessage: '指定日に勤務時間が設定されていません。' }))}
                  isWorkPlanView
                />
                <div className="font-x-large fw-bold ps-2">の作業計画</div>
                <div className="px-2 align-self-center">
                  <BadgeLabel label={workspace?.name || ''} />
                </div>
                <SidebarButton isOpenSidebar={isOpenSidebar} onClick={() => setOpenSidebar(!isOpenSidebar)} />
              </div>
              <div className="d-flex">
                <MoveDropdown />
                {!isPast && isOptimization && (
                  <DropdownButton
                    buttonLabel="最適配置"
                    onClickButton={() => setOpenOptimization(true)}
                    className="ms-2"
                    dropdownItems={optimizationItems}
                  />
                )}
                {!isPast && (
                  <DropdownButton
                    disabled={!plans || isReadOnlyWorkspace}
                    className="ms-2"
                    buttonLabel="作業目標の設定"
                    onClickButton={() => setOpenTargetValuesUpdate(true)}
                    dropdownItems={[
                      { label: '作業目標インポート', onClick: () => setIsImportTargetsDialogOpen(true) },
                      {
                        label: '作業目標エクスポート',
                        onClick: () => {
                          exportTargets(workDate)
                          dispatch(showSuccess())
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </div>

            {plans ? (
              <div className={styles.workPlanContainer}>
                <div className={`${styles.cardContainer} ${isOpenSidebar ? 'w-25' : styles.w20}`}>
                  {sortBy(workPlanCardItems, 'label').map((p, index) => (
                    <div className={styles.workPlanCard} key={`work-plan-card-div-${index}`}>
                      <WorkPlanCard
                        scheduleTypeId={p.scheduleTypeId}
                        key={`work-plan-card-${index}`}
                        label={p.label}
                        color={p.color}
                        unit={p.unit}
                        targetValue={p.targetValue}
                        planValue={p.planValue}
                        date={plans.workDate}
                        businessStartTime={businessStartTime}
                        businessEndTime={businessEndTime}
                        graphRows={p.graphRows}
                        scheduleTypeGroups={scheduleTypeGroups}
                        compareGraphs={compareGraphs}
                        defaultProductivity={p.defaultProductivity}
                        onOpenWorkPlanCard={onOpenWorkPlanCard}
                        onSelectCompareScheduleTypeId={onSelectCompareScheduleTypeId}
                      />
                    </div>
                  ))}
                </div>

                <div className={isOpenSidebar ? 'w-75' : styles.w80}>
                  <Card className="pb-3">
                    <CardBody
                      className={`d-flex ${
                        viewWorkTable === 'workTable' ? 'justify-content-between' : 'justify-content-end'
                      }`}
                    >
                      {viewWorkTable === 'workTable' && (
                        <div>
                          <WorkPlanFilter
                            onChange={setEditGroups}
                            onOpenChange={setIsOpenFilter}
                            setShouldReset={setShouldResetFilters}
                            shouldReset={shouldResetFilters}
                          />
                        </div>
                      )}

                      <div className={`mt-1 d-flex align-items-start ${styles.iconButtonContainer}`}>
                        {viewWorkTable === 'workTable' && (
                          <div className="d-flex">
                            <IconButton
                              outline
                              icon="shift"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenShiftAdjustment(true)}
                              tooltipText="シフト調整"
                              name="shift-adjustment-icon-button"
                              height={30}
                            />
                            <IconButton
                              outline
                              icon="template"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenTemplate(true)}
                              tooltipText="予定テンプレート入力"
                              name="template-icon-button"
                              height={30}
                            />
                            <IconButton
                              outline
                              icon="delete"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenDeleteSchedules(true)}
                              tooltipText="予定一括削除"
                              name="schedule-bulk-delete-icon-button"
                              height={30}
                            />
                          </div>
                        )}
                        <ButtonGroup>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('workTable')}
                            active={viewWorkTable === 'workTable'}
                            name="work-table"
                          >
                            予定入力
                          </Button>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('performanceRateTable')}
                            active={viewWorkTable === 'performanceRateTable'}
                            name="performance-rate-table"
                          >
                            生産性調整
                          </Button>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('assignedNumberTable')}
                            active={viewWorkTable === 'assignedNumberTable'}
                            name="assigned-number-table"
                          >
                            配置人数一覧
                          </Button>
                        </ButtonGroup>
                      </div>
                    </CardBody>

                    <CardBody className="py-0">{tableView}</CardBody>
                  </Card>
                </div>
              </div>
            ) : (
              <div>nodata</div>
            )}
          </div>
          {plans && !isPast && (
            <SubmitFooter
              onCancel={onCancel}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
              updatedBy={planLastUpdater}
              updatedAt={planLastUpdatedAt}
              submitName="work-plan-submit"
            />
          )}
        </>
      </NavMenu>

      {plans && (
        <TargetValuesUpdate
          isOpen={openTargetValuesUpdate}
          workspaceName={workspace?.name || ''}
          workspaceId={workspaceId}
          isPast={isPast}
          onSuccess={onTargetValuesUpdateSuccess}
          onCancel={() => setOpenTargetValuesUpdate(false)}
        />
      )}
      <OptimizationDialog
        apiKey={apiKey}
        magiQannealTenant={magiQannealTenant}
        magiQannealLocation={magiQannealLocation}
        isOpen={openOptimization}
        workspaceId={workspaceId}
        editGroups={editGroups}
        onCancel={() => setOpenOptimization(false)}
        workDate={workDate}
      />
      <ImportOptimizedDialog
        apiKey={apiKey}
        magiQannealTenant={magiQannealTenant}
        magiQannealLocation={magiQannealLocation}
        isOpen={openImportOptimized}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        onCancel={() => setOpenImportOptimized(false)}
        workDate={workDate}
      />
      <DeleteSchedules
        isOpen={openDeleteSchedules}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        workerIds={selectedWorker}
        workDate={workDate}
        onCancel={() => setOpenDeleteSchedules(false)}
      />
      <ImportTemplateDialog
        isOpen={openTemplate}
        workspaceId={workspaceId}
        workerIds={selectedWorker}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        onSuccess={() => {
          setOpenTemplate(false)
          dispatch(showSuccess())
        }}
        onCancel={() => setOpenTemplate(false)}
        onError={() => {
          dispatch(showError())
          setOpenTemplate(false)
        }}
      />
      <ShiftAdjustment
        isOpen={openShiftAdjustment}
        workspaceId={workspaceId}
        workersPlan={workersPlanForShiftAdjustment}
        workerIds={selectedWorker}
        workDate={workDate}
        onCancel={() => setOpenShiftAdjustment(false)}
        onSuccess={onSuccessShiftAdjustment}
        onError={onErrorShiftAdjustment}
      />
      <ImportTargetsDialog
        workDate={workDate}
        isOpen={isImportTargetsDialogOpen}
        onSuccess={handleImportTargetsSuccess}
        onCancel={() => setIsImportTargetsDialogOpen(false)}
      />
    </>
  )
}

export default WorkPlan
