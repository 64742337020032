import axios from 'axios'

import type { ColorType } from 'components/common/types'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { PartialScheduleTypeData } from './schedule_types'
import type { TenantData } from './tenants'
import type { PartialHourlyProductivities } from './workers'
import type { WorkerType } from './workers/types'
import type { WorkspaceData } from './workspaces'
export const PlanUpdateModeTypes = {
  ShiftPlan: 'shiftplan',
  WorkPlan: 'workplan',
} as const

export type PlanUpdateModeType = (typeof PlanUpdateModeTypes)[keyof typeof PlanUpdateModeTypes]

export type PlanResponse = {
  workDate: string
  currentWorkDate: string
  planUpdatedAt: string
  planUpdatedAtByName: string
  workerShiftUpdatedAt: string
  workerShiftUpdatedAtByName: string
  tenant: TenantData
  workspace: WorkspaceData
  partialScheduleTypes: PartialScheduleTypeData[]
  groups: GroupData[]
}

export type GroupData = {
  groupId: number | null
  groupName: string | null
  groupColor: ColorType
  isSupported: boolean
  workersPlan: WorkersPlanData[]
}

export type WorkersPlanData = {
  workerId: number
  workerName: string
  revision: number | null
  workShifts: (number | null)[]
  workScheduleTypes: number[]
  wmsMemberId: string
  skillIds: number[]
  partialHourlyProductivities: PartialHourlyProductivities[]
  updatedAt: string | null
  updatedByName: string | null
  workerType: WorkerType
}

export type PlanListResponse = {
  workDateFrom: string
  workDateTo: string
  currentWorkDate: string
  tenant: TenantData
  workspace: WorkspaceData
  targetScheduleTypes: TargetScheduleTypes
  dailyPlans: DailyPlans[]
}

type TargetScheduleTypes = {
  scheduleTypeIds: number[]
  scheduleTypeNames: string[]
}

export type DailyPlans = {
  workDate: string
  workerCount: number
  plans: number[]
  targets: number[]
}

export type UpdatePlan = {
  updateMode: PlanUpdateModeType
  workersPlan: WorkersPlan
}

export type UpdatePlanBulk = {
  updateMode: string
  workersPlan: WorkersPlan[] | undefined
  updatedByWorkerId?: number
}

export type WorkersPlan = {
  workerId: number | null
  workShifts: (number | null)[]
  workScheduleTypes: number[]
}

export type UpdatePlanResponse = {
  results: UpdatedWorkerPlan[]
}

export type UpdatedWorkerPlan = {
  workerId: number
  workDate: string
  revision: number
  createdAt: string
  createdByName: string
  truncated: boolean
}

export type UpdatePlanAsyncResponse = {
  requestId: string
}

export type PlanCreateStatusResponse = {
  retryInterval: number
  isCompleted: boolean
  results?: UpdatedWorkerPlan[] // isCompletedがtrueの場合のみ返される
}

export type ProductivityPlanResponse = {
  workDate: string
  currentWorkDate: string
  tenant: TenantData
  workspace: WorkspaceData
  partialScheduleType: PartialScheduleTypeData[]
  productivityAdjustments: productivityAdjustments[]
}

export type productivityAdjustments = {
  scheduleTypeId: number
  revision: number
  values: number[]
  updatedAt: string
  updatedByName: string
}

export type UpdateProductivityPlan = {
  productivityAdjustment: ProductivityList
}

type ProductivityList = {
  values: (number | null)[]
}

export type UpdateProductivityPlanResponse = {
  scheduleTypeId: number
  workDate: string
  revision: number
  createdAt: string
  createdByName: string
}

export type UpdateTargetValue = {
  dailyTargets: TargetValueDailyPlan[]
}

type TargetValueDailyPlan = {
  workDate: string
  scheduleTypeId: number
  value: number
}

export type UpdateTargetValueResponse = {
  dailyTargets: TargetValueDailyPlan[]
}

type UpdateWorkerSchedulesType = {
  scheduleTypeId: number | null
  supportWorkspaceId: number | null
  startAt: string
  duration: number
  workerId: number
  groupId: null
}

export type UpdatePlanSchedule = {
  scheduleId: number | null
  schedule: UpdateWorkerSchedulesType | null
  isSupport?: boolean
}

export type WorkerScheduleData = {
  workerId: string | number
  startAt: string
  duration: number
}

// Planデータを日付指定で取得
export const getPlanByDate = (workspaceId: number, workDate: string): Promise<PlanResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// Planデータを日付、workerId指定で取得
export const getPlanByWorkerId = (workspaceId: number, workDate: string, workerId: number): Promise<PlanResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/workers/${workerId}/plan`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// Planデータの一覧取得
export const getPlanList = (workspaceId: number, from?: string, to?: string): Promise<PlanListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/plan-list`

    const queryParams = {
      'work-date-from': from,
      'work-date-to': to,
    }
    axiosClientWithTenantId
      .get(url, { params: queryParams })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// Planデータ作成、更新
export const updatePlan = (
  workspaceId: number,
  workDate: string,
  workerId: number,
  data: UpdatePlan
): Promise<UpdatePlanResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/workers/${workerId}/plan}`

    axiosClientWithTenantId
      .put(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// Planデータ一括作成、更新
export const updatePlanAsync = (
  workspaceId: number,
  workDate: string,
  data: UpdatePlanBulk
): Promise<UpdatePlanAsyncResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan/bulk-create`

    axiosClientWithTenantId
      .put(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getPlanCreateStatus = (
  workspaceId: number,
  workDate: string,
  requestId: string
): Promise<PlanCreateStatusResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/plan/${requestId}/bulk-create-status`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// 生産性調整情報一覧取得
export const getProductivityPlan = (workspaceId: number, workDate: string): Promise<ProductivityPlanResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/productivity-plan`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// 生産性調整情報登録、更新
export const updateProductivityPlan = (
  workspaceId: number,
  workDate: string,
  scheduleTypeId: number,
  data: UpdateProductivityPlan
): Promise<UpdateProductivityPlanResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/work-date/${workDate}/schedule-types/${scheduleTypeId}/productivity-plan`

    axiosClientWithTenantId
      .put(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

// 作業種別の目標数を登録・更新
export const updateTargetValue = (workspaceId: number, data: UpdateTargetValue): Promise<UpdateTargetValueResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/target-value`
    axiosClientWithTenantId
      .put(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const shiftUploadUrl = (
  fileName: string
): Promise<{
  uploadUrl: string
}> => {
  return new Promise((resolve, reject) => {
    const url = '/shift-upload-url'

    axiosClientWithTenantId
      .post(url, { fileName })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const putUploadUrl = (url: string, csvContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'text/csv',
    }
    axios
      .put(url, csvContent, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}
