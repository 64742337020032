import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/plans'
import { NOT_FOUND_ERROR_STATUS_CODE, handleApiError } from 'api/utils'

import { sleep } from 'slices/utils'

import type { Action, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type DailyPlanData = {
  scheduleTypeId: number
  scheduleTypeName: string
  plan: number
  target: number
}
export type DailyPlan = { data: DailyPlanData[]; workDate: string; workerCount: number }

type PlanListData = Omit<API.PlanListResponse, 'dailyPlans'> & {
  dailyPlans: DailyPlan[]
}

type PlanState = {
  isRequesting: boolean
  errorMessage: string
  plans?: API.PlanResponse
  planList?: PlanListData
  productivityAdjustments?: API.productivityAdjustments[]
  scheduleTruncated: boolean
}

export type UpdateProductivityPlanWithScheduleTypeId = API.UpdateProductivityPlan & {
  scheduleTypeId: number
}

const initialState: PlanState = {
  isRequesting: false,
  errorMessage: '',
  plans: undefined,
  planList: undefined,
  productivityAdjustments: undefined,
  scheduleTruncated: false,
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
      state.plans = initialState.plans
      state.planList = initialState.planList
      state.productivityAdjustments = initialState.productivityAdjustments
      state.isRequesting = false
    },
    apiFailureUnclearPlans: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
      state.isRequesting = false
    },
    getPlanListSuccess: (state, action: PayloadAction<API.PlanListResponse>) => {
      const dailyPlans = action.payload.dailyPlans
        .filter(dailyPlan => dailyPlan.workerCount > 0)
        .map(dailyPlan => {
          const data = action.payload.targetScheduleTypes.scheduleTypeIds.map((id, i) => ({
            scheduleTypeId: id,
            scheduleTypeName: action.payload.targetScheduleTypes.scheduleTypeNames[i],
            target: dailyPlan.targets[i],
            plan: dailyPlan.plans[i],
          }))
          return { ...dailyPlan, data }
        })
      state.planList = { ...action.payload, dailyPlans }
      state.isRequesting = false
    },
    updatePlanSuccess: (state, action: PayloadAction<API.UpdatePlanResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
      state.isRequesting = false
    },
    getPlanSuccess: (state, action: PayloadAction<API.PlanResponse>) => {
      const assignedGroups = _.sortBy(
        action.payload.groups.filter(group => group.groupName !== '未所属'),
        'groupName'
      )
      const unassignedGroup = action.payload.groups.find(group => group.groupName === '未所属')
      const sortedGroups = (unassignedGroup ? [...assignedGroups, unassignedGroup] : assignedGroups).map(group => ({
        ...group,
        workersPlan: _.sortBy(group.workersPlan, 'workerName'),
      }))
      state.plans = {
        ...action.payload,
        groups: sortedGroups,
      }
      state.isRequesting = false
    },
    updatePlanAsyncSuccess: (state, action: PayloadAction<API.PlanCreateStatusResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
      state.isRequesting = false
    },
    getProductivityPlanSuccess: (state, action: PayloadAction<API.ProductivityPlanResponse>) => {
      state.productivityAdjustments = action.payload.productivityAdjustments
      state.isRequesting = false
    },
    requestFinished: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  apiFailureUnclearPlans,
  getPlanListSuccess,
  getPlanSuccess,
  updatePlanSuccess,
  updatePlanAsyncSuccess,
  getProductivityPlanSuccess,
  requestFinished,
} = plansSlice.actions

// Planデータを日付指定で取得
export const getPlanByDate =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getPlanByDate(workspaceId, workDate)
      .then((res: API.PlanResponse) => dispatch(getPlanSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

// 取得したPlanデータでシフトがあるかのチェックをする
export const getPlanByDateWithShiftCheck =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getPlanByDate(workspaceId, workDate)
      .then((res: API.PlanResponse) => {
        const workersPlan = res.groups.flatMap(group => group.workersPlan)
        if (workersPlan.some(plan => plan.workShifts.some(shift => shift && shift > 0))) {
          dispatch(getPlanSuccess(res))
          return
        }

        // shiftのデータが存在しない場合
        dispatch(apiFailureUnclearPlans({ errorMessage: NOT_FOUND_ERROR_STATUS_CODE }))
      })
      .catch((res: AxiosError) => {
        // 作業計画画面から別の日付に移動する際に選択先の日付が存在しない場合､ エラー処理が実行される
        // plans が空になると作業計画画面が nodata になるのでここでは getPlanByDate がエラーでも plans を空にしない
        handleApiError(res, dispatch, apiFailureUnclearPlans)
      })
  }

// Planデータを日付、workerId指定で取得
export const getPlanByWorkerId =
  (workspaceId: number, workDate: string, workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getPlanByWorkerId(workspaceId, workDate, workerId)
      .then((res: API.PlanResponse) => dispatch(getPlanSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

// Planデータの一覧取得
export const getPlanList =
  (workspaceId: number, from?: string, to?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getPlanList(workspaceId, from, to)
      .then((res: API.PlanListResponse) => dispatch(getPlanListSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

const bulkUpdatePlan = async (
  workspaceId: number,
  workDate: string,
  data: API.UpdatePlanBulk,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>
) => {
  const { requestId } = await API.updatePlanAsync(workspaceId, workDate, data)
  if (!requestId) {
    return new Error('requestId is not found')
  }

  const callGetPlanCreateStatus = async () => {
    const updateStatus = await API.getPlanCreateStatus(workspaceId, workDate, requestId)
    if (updateStatus.isCompleted) {
      dispatch(updatePlanAsyncSuccess(updateStatus))
      return
    }
    const retryInterval = updateStatus.retryInterval
    retryInterval > 0 && (await sleep(retryInterval))
    await callGetPlanCreateStatus()
  }

  await callGetPlanCreateStatus()
}

// Planデータ一括作成、更新
export const updatePlanBulkCreate =
  (workspaceId: number, workDate: string, data: API.UpdatePlanBulk): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      bulkUpdatePlan(workspaceId, workDate, data, dispatch)
      dispatch(requestFinished())
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

// ProductivityPlanデータの一覧取得
export const getProductivityPlan =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getProductivityPlan(workspaceId, workDate)
      .then((res: API.ProductivityPlanResponse) => {
        dispatch(getProductivityPlanSuccess(res))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

// PlanとProductivityPlanを更新
export const updateProductivityPlanAndPlanBulkCreate =
  (
    workspaceId: number,
    workDate: string,
    planData: API.UpdatePlanBulk | undefined,
    productivityPlanData: UpdateProductivityPlanWithScheduleTypeId[] | undefined
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      if (productivityPlanData) {
        await Promise.all(
          productivityPlanData.map(async data => {
            await API.updateProductivityPlan(workspaceId, workDate, data.scheduleTypeId, data)
          })
        )
      }

      if (!planData) {
        dispatch(requestFinished())
        return
      }

      bulkUpdatePlan(workspaceId, workDate, planData, dispatch)
      dispatch(requestFinished())
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }
// 作業種別の目標数作成、更新
export const updateTargetValue =
  (workspaceId: number, data: API.UpdateTargetValue): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateTargetValue(workspaceId, data)
      .then(() => dispatch(requestFinished()))

      .catch((res: AxiosError) => handleApiError(res, dispatch, apiFailure))
  }

export const importShift =
  (fileName: string, csvContent: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.shiftUploadUrl(fileName)
      await API.putUploadUrl(response.uploadUrl, csvContent)
      dispatch(requestFinished())
    } catch (error) {
      handleApiError(error as AxiosError, dispatch, apiFailure)
    }
  }
export const selectPlansStatus = (state: RootState) => ({ ...state.plans })

export default plansSlice.reducer
