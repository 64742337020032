import type { CreateResponse, DeleteResponse, UpdateResponse } from 'api/types'

import type { ColorType } from 'components/common/types'

import { axiosClient, axiosClientWithTenantId } from 'AxiosClientProvider'

import type { PartialTenantData, TenantData } from './tenants'

export type PartialWorkspaceData = {
  id: number
  workDate: string
  revision: number
  name: string
  updatedAt: string
  updatedByName: string | null
  scheduleTypeNames: string[]
}

export type WorkspaceData = PartialWorkspaceData & {
  autoArchiveTime: string | null
  memberIds: string[]
  relatedWorkspaceIds: number[]
}

export type WorkspaceListResponse = {
  partialTenant: PartialTenantData
  partialWorkspaces: PartialWorkspaceData[]
}

export type WorkspaceResponse = {
  workDate: string
  tenant: TenantData
  workspace: WorkspaceData
}

export type WorkerCountsResponse = {
  workspaceId: number
  workspaceName: string
  date: string
  data: ScheduleTypeWithWorkerCounts[]
}

export type ScheduleTypeWithWorkerCounts = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  totalCount: number
  data: WorkerCount[]
}

export type WorkerCount = {
  time: string
  workerCount: number
}

export type EditWorkspaceProps = {
  workDate?: string
  name: string
  memberIds: string[]
  relatedWorkspaceIds: number[]
  autoArchiveTime: string
}

// idがnullの場合は新規追加
// schedulesがnullの場合は削除
export type EditTemplateProps = {
  id: number | null
  schedules: (number | null)[] | null // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  name?: string
}

export type TemplateListResponse = {
  templates: {
    id: number
    name: string
  }[]
}

export type TemplateDataResponse = {
  id: number
  name: string
  schedules: (number | null)[] // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  updatedAt: string
  updatedByName: string
}

type UploadTargetsResponse = {
  updateData: {
    revision: number | null
    workDate: string
    scheduleTypeId: number | null
    targetValue: number | null
    errorInfo?: string
  }[]
}

export const getWorkspacesList = (tenantId: number | undefined, workDate?: string): Promise<WorkspaceListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/workspaces`

    const paramData = workDate ? { 'work-date': workDate } : {}
    axiosClient
      .get(url, { params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkspace = (workspaceId: number, workDate?: string): Promise<WorkspaceResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}`

    const paramData = workDate ? { workDate } : {}

    axiosClientWithTenantId
      .get(url, { params: paramData })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorkspace = (data: EditWorkspaceProps): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = '/workspaces'

    axiosClientWithTenantId
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorkspace = (workspaceId: number, data: EditWorkspaceProps): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}`

    axiosClientWithTenantId
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorkspace = (workspaceId: number): Promise<DeleteResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const uploadTargets = (workspaceId: string, fileData: FormData): Promise<UploadTargetsResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/target-value-with-file`
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    axiosClientWithTenantId
      .post(url, fileData, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
