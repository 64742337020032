import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/official_duties'
import { handleApiError } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type OfficialDutiesState = {
  partialOfficialDutiesList: API.PartialOfficialDutiesListData[]
  officialDuties?: API.OfficialDutiesData
  isRequesting: boolean
  errorMessage: string
}

const initialState: OfficialDutiesState = {
  isRequesting: false,
  errorMessage: '',
  officialDuties: undefined,
  partialOfficialDutiesList: [],
}

export const officialDutiesSlice = createSlice({
  name: 'duties',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getOfficialDutiesListSuccess: (state, action: PayloadAction<API.OfficialDutiesListResponse>) => {
      state.isRequesting = false
      state.partialOfficialDutiesList = action.payload.partialOfficialDutiesList
    },
    getOfficialDutiesSuccess: (state, action: PayloadAction<API.OfficialDutiesDetailResponse>) => {
      state.isRequesting = false
      state.officialDuties = action.payload.officialDuties
    },
    createOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    updateOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
    deleteOfficialDutiesSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getOfficialDutiesListSuccess,
  getOfficialDutiesSuccess,
  createOfficialDutiesSuccess,
  updateOfficialDutiesSuccess,
  deleteOfficialDutiesSuccess,
} = officialDutiesSlice.actions

export const getOfficialDutiesList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  API.getOfficialDutiesList()
    .then((res: API.OfficialDutiesListResponse) => dispatch(getOfficialDutiesListSuccess(res)))
    .catch((res: AxiosError) => {
      handleApiError(res, dispatch, apiFailure)
    })
}

export const getOfficialDuties =
  (id: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getOfficialDuties(id)
      .then((res: API.OfficialDutiesDetailResponse) => dispatch(getOfficialDutiesSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createOfficialDuties =
  (data: API.OfficialDutiesEditData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createOfficialDuties(data)
      .then(res => {
        dispatch(createOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
        dispatch(getOfficialDuties(res.id))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateOfficialDuties =
  (id: number, data: API.OfficialDutiesEditData): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateOfficialDuties(id, data)
      .then(() => {
        dispatch(updateOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
        dispatch(getOfficialDuties(id))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteOfficialDuties =
  (id: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteOfficialDuties(id)
      .then(() => {
        dispatch(deleteOfficialDutiesSuccess())
        dispatch(getOfficialDutiesList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectOfficialDutiesStatus = (state: RootState) => ({ ...state.officialDuties })

export default officialDutiesSlice.reducer
