import { createSlice } from '@reduxjs/toolkit'
import { sortBy, partition } from 'es-toolkit'

import { handleApiError } from 'api/utils'
import * as API from 'api/workers'
import { WorkerTypes } from 'api/workers/constants'
import type { WorkerType } from 'api/workers/types'

import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type CreateUpdateParam = {
  createData: API.WorkerScheduleUpdateType[]
  updateData: Array<{ data: API.WorkerScheduleUpdateType; scheduleId: number }>
}

type WorkersState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkers: API.PartialWorkerData[]
  worker?: API.WorkerData
  downloadUrl: string | undefined
}

const initialState: WorkersState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkers: [],
  worker: undefined,
  downloadUrl: undefined,
}

export const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkerListSuccess: (state, action: PayloadAction<API.WorkerListResponse>) => {
      state.isRequesting = false
      const [regularWorkers, spotWorkers] = partition(
        action.payload.partialWorkers,
        worker => worker.workerType === WorkerTypes.RegularMember
      )

      const sortedRegularWorkers = sortBy(regularWorkers, ['wmsMemberId'])
      const sortedSpotWorkers = sortBy(spotWorkers, ['id'])
      state.partialWorkers = sortedRegularWorkers.concat(sortedSpotWorkers)
    },
    getWorkerSuccess: (state, action: PayloadAction<API.WorkerResponse>) => {
      state.isRequesting = false
      state.worker = action.payload.worker
    },

    requestSuccess: state => {
      state.isRequesting = false
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<API.CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
  },
})

export const {
  startRequest,
  apiFailure,
  getWorkerListSuccess,
  getWorkerSuccess,
  requestSuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
} = workersSlice.actions

export const getWorkerList =
  (tenantId?: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    API.getWorkerList(tenantId ?? commonParams(getState).tenantId)
      .then((res: API.WorkerListResponse) => dispatch(getWorkerListSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getWorker =
  (workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getWorker(workerId)
      .then((res: API.WorkerResponse) => dispatch(getWorkerSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createWorker =
  (data: API.CreateWorkerEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createWorker(data)
      .then(res => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
        dispatch(getWorker(res.id))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateWorker =
  (workerId: number, data: API.UpdateWorkerEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateWorker(workerId, data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
        dispatch(getWorker(workerId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteWorker =
  (workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteWorker(workerId)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const importWorkers =
  (fileName: string, workerType: WorkerType, csvContent: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.workersUploadUrl(fileName, workerType)
      await API.putUploadUrl(response.uploadUrl, csvContent)
      dispatch(requestSuccess())
    } catch (error) {
      handleApiError(error as AxiosError, dispatch, apiFailure)
    }
  }

export const exportWorkers =
  (data: API.ExportWorkersDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const exportDataResponse = await API.exportWorkers(data)
      if (exportDataResponse?.downloadUrl) {
        return dispatch(getExportDataUrlSuccess(exportDataResponse))
      }
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

export const selectWorkersStatus = (state: RootState) => ({ ...state.workers })

export default workersSlice.reducer
