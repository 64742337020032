import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/reports'
import { compactAverage, handleApiError } from 'api/utils'

import { getSplitPeriods } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ReportsState = {
  isRequesting: boolean
  errorMessage: string
  reportAverages: API.ReportAverage[]
  productivity: API.ReportProductivityResponse | undefined
  hourlyPlanAccuracies: API.HourlyPlanAccuracy[]
  dailyPlanAccuracies: API.DailyPlanAccuracy[]
  downloadUrl?: string
}

const initialState: ReportsState = {
  isRequesting: false,
  errorMessage: '',
  reportAverages: [],
  productivity: undefined,
  hourlyPlanAccuracies: [],
  dailyPlanAccuracies: [],
  downloadUrl: undefined,
}

interface ExtendedDailyWork extends API.DailyWork {
  hourlyAvgProductivityPool: (number | null)[]
}
interface ExtendedProductivityWorker extends API.ProductivityWorker {
  dailyWorkData: ExtendedDailyWork[]
}
interface ExtendedProductivityGroup extends API.ProductivityGroup {
  workers: ExtendedProductivityWorker[]
}
interface ExtendedReportProductivity extends API.ReportProductivityResponse {
  groups: ExtendedProductivityGroup[]
  dailyWorkData: ExtendedDailyWork[]
}

// hourlyAvgProductivity の集計をするために productivityList を拡張する
const extendProductivityList = (productivityList: API.ReportProductivityResponse[]) => {
  return productivityList.map<ExtendedReportProductivity>(productivity => {
    const groups = productivity.groups.map<ExtendedProductivityGroup>(productivityGroup => {
      const workers = productivityGroup.workers.map<ExtendedProductivityWorker>(productivityWorker => {
        const dailyWorkData = productivityWorker.dailyWorkData.map<ExtendedDailyWork>(dailyWork => {
          return { ...dailyWork, hourlyAvgProductivityPool: [dailyWork.hourlyAvgProductivity] }
        })
        return { ...productivityWorker, dailyWorkData }
      })
      return { ...productivityGroup, workers }
    })
    const dailyWorkData = productivity.dailyWorkData.map<ExtendedDailyWork>(dailyWork => {
      return { ...dailyWork, hourlyAvgProductivityPool: [dailyWork.hourlyAvgProductivity] }
    })
    return { ...productivity, groups, dailyWorkData }
  })
}

// hourlyAvgProductivityPool を集計する
const accumulateProductivity = (extendedProductivity: ExtendedReportProductivity) => {
  const accumulatedGroups = extendedProductivity.groups.map<API.ProductivityGroup>(productivityGroup => {
    const workers = productivityGroup.workers.map<API.ProductivityWorker>(productivityWorker => {
      const dailyWorkData = productivityWorker.dailyWorkData.map<API.DailyWork>(dailyWork => {
        return {
          scheduleTypeId: dailyWork.scheduleTypeId,
          scheduleTypeName: dailyWork.scheduleTypeName,
          scheduleTypeColor: dailyWork.scheduleTypeColor,
          unit: dailyWork.unit,
          hourlyAvgProductivity: compactAverage(dailyWork.hourlyAvgProductivityPool),
          data: dailyWork.data,
        }
      })
      return { ...productivityWorker, dailyWorkData }
    })
    return { ...productivityGroup, workers }
  })
  const dailyWorkData = extendedProductivity.dailyWorkData.map<ExtendedDailyWork>(dailyWork => {
    return {
      ...dailyWork,
      hourlyAvgProductivity: compactAverage(dailyWork.hourlyAvgProductivityPool),
    }
  })
  return { ...extendedProductivity, groups: accumulatedGroups, dailyWorkData }
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getReportAverageSuccess: (state, action: PayloadAction<API.ReportAverageResponse>) => {
      state.isRequesting = false
      state.reportAverages = action.payload.data
    },
    getReportProductivitySuccess: (state, action: PayloadAction<API.ReportProductivityResponse>) => {
      state.isRequesting = false
      state.productivity = action.payload
      state.productivity.groups = _.orderBy(action.payload.groups, ['groupName'], ['asc'])
    },
    getReportPlanAccuracySuccess: (state, action: PayloadAction<API.ReportDailyPlanAccuracyResponse>) => {
      state.isRequesting = false
      state.dailyPlanAccuracies = action.payload.data
    },
    getReportHourlyPlanAccuracySuccess: (state, action: PayloadAction<API.ReportHourlyPlanAccuracyResponse>) => {
      state.isRequesting = false
      state.hourlyPlanAccuracies = action.payload.data
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<API.CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getReportAverageSuccess,
  getReportProductivitySuccess,
  getReportPlanAccuracySuccess,
  getReportHourlyPlanAccuracySuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
} = reportsSlice.actions

export const getReportAverage =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getReportAverage(workspaceId, from, to)
      .then((res: API.ReportAverageResponse) => dispatch(getReportAverageSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

const dailyWorkDataMerge = (dstData: ExtendedDailyWork[], srcData: ExtendedDailyWork[]) => {
  const scheduleTypeIds = _.uniq([...dstData.map(d => d.scheduleTypeId), ...srcData.map(d => d.scheduleTypeId)])
  return scheduleTypeIds.map(id => {
    const dstTarget = dstData.find(d => d.scheduleTypeId === id)
    const srcTarget = srcData.find(d => d.scheduleTypeId === id)
    if (dstTarget && srcTarget) {
      return {
        ...dstTarget,
        data: _.unionBy(dstTarget.data, srcTarget.data, 'date'),
        hourlyAvgProductivityPool: [...dstTarget.hourlyAvgProductivityPool, ...srcTarget.hourlyAvgProductivityPool],
      }
    }
    return (dstTarget || srcTarget)!
  })
}

const workerMerge = (dstWorkers: ExtendedProductivityWorker[], srcWorkers: ExtendedProductivityWorker[]) => {
  const workerIds = _.uniq([...dstWorkers.map(w => w.workerId), ...srcWorkers.map(w => w.workerId)])
  return workerIds.map(id => {
    const dstTarget = dstWorkers.find(w => w.workerId === id)
    const srcTarget = srcWorkers.find(w => w.workerId === id)
    if (dstTarget && srcTarget) {
      return {
        ...dstTarget,
        dailyWorkData: dailyWorkDataMerge(dstTarget.dailyWorkData, srcTarget.dailyWorkData),
      }
    }
    return (dstTarget || srcTarget)!
  })
}

const groupMerge = (dstGroups: ExtendedProductivityGroup[], srcGroups: ExtendedProductivityGroup[]) => {
  // groupIdがnullの時、未所属のグループか、応援を示すグループの2通りがあるため、未所属のグループを取得するときはsupportedWorkspaceIdがnullのものを選択する
  const dstWithoutSupportGroup = dstGroups.filter(g => g.supportedWorkspaceId === null)
  const srcWithoutSupportGroup = srcGroups.filter(g => g.supportedWorkspaceId === null)
  const groupIds = _.uniq([
    ...dstWithoutSupportGroup.map(g => g.groupId),
    ...srcWithoutSupportGroup.map(g => g.groupId),
  ])

  const supportedWorkspaceIds = _.uniq([
    ...dstGroups.map(g => g.supportedWorkspaceId),
    ...srcGroups.map(g => g.supportedWorkspaceId),
  ]).filter(id => id !== null)

  return groupIds
    .map(id => {
      const dstTarget = dstWithoutSupportGroup.find(g => g.groupId === id)
      const srcTarget = srcWithoutSupportGroup.find(g => g.groupId === id)
      if (dstTarget && srcTarget) {
        return {
          ...dstTarget,
          workers: workerMerge(dstTarget.workers, srcTarget.workers),
        }
      }
      return (dstTarget || srcTarget)!
    })
    .concat(
      supportedWorkspaceIds.map(id => {
        const dstTarget = dstGroups.find(g => g.supportedWorkspaceId === id)
        const srcTarget = srcGroups.find(g => g.supportedWorkspaceId === id)
        if (dstTarget && srcTarget) {
          return {
            ...dstTarget,
            workers: workerMerge(dstTarget.workers, srcTarget.workers),
          }
        }
        return (dstTarget || srcTarget)!
      })
    )
}

export const getReportProductivity =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    const splitPeriods = getSplitPeriods(from, to)
    const promises: Promise<API.ReportProductivityResponse>[] = splitPeriods.map((period: [string, string]) =>
      API.getReportProductivity(workspaceId, period[0], period[1])
    )

    await Promise.all(promises)
      .then(productivityList => {
        // hourlyAvgProductivity の集計をするために productivityList を拡張する
        const extendedProductivityList = extendProductivityList(productivityList)

        // 複数の productivity を合成する
        const productivity = extendedProductivityList.reduce(
          (acc: ExtendedReportProductivity, cur) => {
            // dailyWorkData を合成する
            // ワークスペースが存在しない期間の場合、acc.dailyWorkDataが空配列になってしまうため、cur.dailyWorkDataからデータを取り込む
            const dailyWorkData = cur.dailyWorkData.map(dailyWork => {
              const existingData = acc.dailyWorkData.find(d => d.scheduleTypeId === dailyWork.scheduleTypeId)
              return existingData
                ? {
                    ...dailyWork,
                    data: dailyWork.data.concat(existingData.data || []),
                    hourlyAvgProductivityPool: dailyWork.hourlyAvgProductivityPool.concat(
                      existingData.hourlyAvgProductivityPool
                    ),
                  }
                : dailyWork
            })

            return {
              ...acc,
              dailyWorkData,
              groups: groupMerge(acc.groups, cur.groups),
            }
          },
          {
            workspaceId: workspaceId,
            workspaceName: extendedProductivityList[0].workspaceName,
            dailyWorkData: [],
            groups: [],
          }
        )

        // hourlyAvgProductivity を集計する
        const accumulatedProductivity = accumulateProductivity(productivity)

        dispatch(getReportProductivitySuccess(accumulatedProductivity))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getReportPlanAccuracy =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getReportPlanAccuracy(workspaceId, from, to)
      .then((res: API.ReportDailyPlanAccuracyResponse) => dispatch(getReportPlanAccuracySuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getReportHourlyPlanAccuracy =
  (workspaceId: number, date: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getReportHourlyPlanAccuracy(workspaceId, date)
      .then((res: API.ReportHourlyPlanAccuracyResponse) => dispatch(getReportHourlyPlanAccuracySuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getExportDataUrl =
  (data: API.CreateExportDataInfo): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const { requestId } = await API.createExportData(data)
      if (!requestId) {
        return
      }

      const fetchExportDataUrl = async () => {
        const exportDataResponse = await API.getExportData(requestId)
        if (exportDataResponse?.downloadUrl) {
          dispatch(getExportDataUrlSuccess(exportDataResponse))
          return
        }
        fetchExportDataUrl()
      }

      await fetchExportDataUrl()
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

export const selectReportsStatus = (state: RootState) => ({ ...state.reports })

export default reportsSlice.reducer
