import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'

export type SkillResponseV1 = {
  skillId: number
  name: string
  updatedBy: string
  updatedAt: string
}

export type SkillData = {
  id: number
  workDate: string
  revision: number
  name: string
  updatedAt: string
  updatedByName: string
}

export type SkillResponse = {
  tenant: TenantData
  skill: SkillData
}

export type SkillListResponse = {
  partialTenant: PartialTenantData
  skills: SkillData[]
}

export const getSkillList = (): Promise<SkillListResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/skills'

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getSkill = (skillId: number): Promise<SkillResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/skills/${skillId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createSkill = (name: string): Promise<CreateResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/skills'

    axiosClientWithTenantId
      .post(url, { name })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateSkill = (skillId: number, name: string): Promise<UpdateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/skills/${skillId}`

    axiosClientWithTenantId
      .patch(url, { name })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteSkill = (skillId: number): Promise<DeleteResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/skills/${skillId}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
