import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import type { RoleType } from 'api/users'
import { Role } from 'api/users/constants'

import { clearErrorMessage, selectUsersStatus, updateUserTenant } from 'slices/usersSlice'

import { CardRadioButton, CustomModal } from 'components/common'
import type { CardRadioButtonItem } from 'components/common/types'

type Props = {
  userId: string
  tenantId: number
  role: RoleType
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const AccountTypeChange: React.FC<Props> = props => {
  const { userId, tenantId, role, isOpen, onSuccess, onCancel } = props
  const [newRole, setNewRole] = React.useState(role)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const dispatch = useDispatch()

  const unchanged = React.useMemo(() => newRole === role, [newRole, role])

  React.useEffect(() => {
    setNewRole(role)
  }, [role])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('保存できませんでした。')
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const radioButtonItems: CardRadioButtonItem[] = [
    {
      value: Role.Admin,
      label: 'オーナー',
      subtitle: '全てのワークスペースの管理、ユーザーの招待/削除が行える管理者アカウント',
    },
    {
      value: Role.ProcessAdmin,
      label: 'オペレーター',
      subtitle: '特定のワークスペースの管理が行える利用者アカウント',
    },
  ]

  const onRadioButtonClick = (value: string) => {
    setNewRole(value === Role.Admin ? Role.Admin : Role.ProcessAdmin)
  }

  const onCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    setNewRole(role)
    onCancel()
  }

  const onSaveClick = () => {
    setSubmitted(true)
    dispatch(updateUserTenant(userId, tenantId, { isActive: true, role: newRole }))
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="アカウントタイプの変更"
      errorMessage={modalErrorMessage}
      onCancel={onCancelClick}
      onApprove={onSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      approveDisabled={unchanged}
    >
      <div className="mb-3">アカウントタイプを変更できます。</div>
      <CardRadioButton items={radioButtonItems} name="userAuthority" selected={newRole} onClick={onRadioButtonClick} />
    </CustomModal>
  )
}

export default AccountTypeChange
