import { axiosClientWithTenantId } from 'AxiosClientProvider'

export type TemplateDetails = {
  id: number
  name: string
}
export type TemplateListResponse = {
  templates: TemplateDetails[]
}

export type TemplateDataResponse = {
  id: number
  name: string
  schedules: (number | null)[] // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  workspaces: (number | null)[] // 24時間を15分ごとに分割した96の配列にworkspaceIdを格納する
  updatedAt: string
  updatedByName: string
}

// idがnullの場合は新規追加
// scheduleIds,workspaceIdsがnullの場合は削除
export type TemplateProps = {
  id: number | null
  schedules: (number | null)[] | null // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  workspaces: (number | null)[] | null // 24時間を15分ごとに分割した96の配列にworkspaceIdを格納する
  name?: string
}

export type EditTemplateProps = {
  id: number | null
  ids: ({ id: number; isSupport: boolean } | null)[]
  name?: string
}

export const getTemplateList = (workspaceId: number): Promise<TemplateListResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/templates`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTemplateData = (workspaceId: number, templateId: number): Promise<TemplateDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/templates/${templateId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTemplateData = (workspaceId: number, data: TemplateProps): Promise<TemplateDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workspaces/${workspaceId}/templates`

    axiosClientWithTenantId
      .put(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
