import axios from 'axios'

import { axiosClient, axiosClientWithTenantId } from 'AxiosClientProvider'

import type { SkillData } from './skills'
import type { PartialTenantData, TenantData } from './tenants'
import type { CreateResponse, UpdateResponse, DeleteResponse } from './types'
import type { WorkerType } from './workers/types'
import type { PartialWorkspaceData } from './workspaces'
export type PartialHourlyProductivities = {
  scheduleTypeId: number
  value: number | null
}

export type HourlyProductivities = PartialHourlyProductivities & {
  average: number | null
}

export type WorkersUploadResponse = {
  uploadUrl: string
}

export type PartialWorkerData = {
  id: number
  workDate: string
  revision: number
  name: string
  wmsMemberId: string | null
  workerType: WorkerType
  workspaceId: number | null
  groupId: number | null
  groupLeader: boolean
  skillIds: number[]
  partialHourlyProductivities: PartialHourlyProductivities[]
  updatedAt: string
  updatedByName: string | null
}

export type WorkerListResponse = {
  count: number
  partialTenant: PartialTenantData
  partialWorkspaces: PartialWorkspaceData[]
  partialWorkers: PartialWorkerData[]
}

export type WorkerData = Omit<PartialWorkerData, 'partialHourlyProductivities'> & {
  officialDutyId: number | null
  hiredAt: string | null
  hourlyProductivities: HourlyProductivities[]
}

export type WorkerResponse = {
  workDate: string
  tenant: TenantData
  partialWorkspace: PartialWorkspaceData
  worker: WorkerData
}

export type WorkerScheduleUpdateType = {
  scheduleTypeId: number | null
  startAt: string
  duration: number
}

export type UpdateWorkerEditDataType = {
  workDate?: string
  name?: string
  officialDutyId?: number | null
  workspaceId?: number
  groupId?: number | null
  groupLeader?: boolean
  hiredAt?: string | null
  skillIds?: number[]
  hourlyProductivities?: PartialHourlyProductivities[]
}

export type CreateWorkerEditDataType = {
  workDate?: string
  name: string
  wmsMemberId: string
  workerType: WorkerType
  officialDutyId: number | null
  workspaceId: number | null
  groupId: number | null
  groupLeader: boolean
  hiredAt: string | null
  skillIds: number[]
  hourlyProductivities: PartialHourlyProductivities[]
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export type WorkerEditType = {
  id: number | undefined
  name: string | undefined
  wmsMemberId: string | null | undefined
  officialDuties: { key: number; value: string } | undefined
  workspace: { key: number; value: string } | undefined
  groupId: number | undefined
  groupLeader: boolean
  hiredAt: string | undefined
  skills: SkillData[]
  hourlyProductivities: HourlyProductivities[]
  workerTypes: { key: WorkerType; value: string; description?: string } | undefined
}

export type ExportWorkersDataType = {
  workerType: WorkerType
  replaceAverageProductivity: boolean
  includeUnaffiliatedWorker: boolean
  targetWorkspaceIds: number[]
}

export const getWorkerList = (tenantId: number | undefined): Promise<WorkerListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/workers`

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorker = (workerId: number): Promise<WorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workers/${workerId}`

    axiosClientWithTenantId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorker = (data: CreateWorkerEditDataType): Promise<CreateResponse> =>
  new Promise((resolve, reject) => {
    const url = '/workers'

    axiosClientWithTenantId
      .post(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorker = (workerId: number, data: UpdateWorkerEditDataType): Promise<UpdateResponse> =>
  new Promise((resolve, reject) => {
    const url = `/workers/${workerId}`

    axiosClientWithTenantId
      .patch(url, { ...data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorker = (workerId: number): Promise<DeleteResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/workers/${workerId}`

    axiosClientWithTenantId
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const workersUploadUrl = (fileName: string, workerType: WorkerType): Promise<WorkersUploadResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/workers/upload-url'

    axiosClientWithTenantId
      .post(url, { fileName, workerType })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const putUploadUrl = (url: string, csvContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'text/csv',
    }
    axios
      .put(url, csvContent, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}

export const exportWorkers = (data: ExportWorkersDataType): Promise<CheckExportDataReadyResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/workers/data-export'

    axiosClientWithTenantId
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
