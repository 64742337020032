import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/skills'
import { handleApiError } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type SkillState = {
  isRequesting: boolean
  errorMessage: string
  skill?: API.SkillData
  skills: API.SkillData[]
}

const initialState: SkillState = {
  isRequesting: false,
  errorMessage: '',
  skill: undefined,
  skills: [],
}

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getSkillListSuccess: (state, action: PayloadAction<API.SkillListResponse>) => {
      state.isRequesting = false
      state.skills = action.payload.skills
    },
    getSkillSuccess: (state, action: PayloadAction<API.SkillResponse>) => {
      state.isRequesting = false
      state.skill = action.payload.skill
    },
    createSkillSuccess: state => {
      state.isRequesting = false
    },
    updateSkillSuccess: state => {
      state.isRequesting = false
    },
    deleteSkillSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getSkillListSuccess,
  getSkillSuccess,
  createSkillSuccess,
  updateSkillSuccess,
  deleteSkillSuccess,
} = skillsSlice.actions

export const getSkillList = (): AppThunk => async dispatch => {
  dispatch(startRequest())

  API.getSkillList()
    .then((res: API.SkillListResponse) => dispatch(getSkillListSuccess(res)))
    .catch((res: AxiosError) => {
      handleApiError(res, dispatch, apiFailure)
    })
}

export const getSkill =
  (skillId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getSkill(skillId)
      .then((res: API.SkillResponse) => dispatch(getSkillSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createSkill =
  (name: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createSkill(name)
      .then(() => {
        dispatch(createSkillSuccess())
        dispatch(getSkillList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateSkill =
  (skillId: number, name: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateSkill(skillId, name)
      .then(() => {
        dispatch(updateSkillSuccess())
        dispatch(getSkillList())
        dispatch(getSkill(skillId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteSkill =
  (skillId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteSkill(skillId)
      .then(() => {
        dispatch(deleteSkillSuccess())
        dispatch(getSkillList())
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectSkillsStatus = (state: RootState) => ({ ...state.skills })

export default skillsSlice.reducer
