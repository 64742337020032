import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/schedule_types'
import { handleApiError } from 'api/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type ExtendAllScheduleTypeListData = API.PartialScheduleTypeData & {
  workspaceName: string
}

type ScheduleTypeState = {
  isRequesting: boolean
  errorMessage: string
  scheduleType?: API.ScheduleTypeData
  partialScheduleTypes: API.PartialScheduleTypeData[]
  allScheduleTypes: ExtendAllScheduleTypeListData[]
  scheduleTypeWorkspaceId: number | undefined
}

const initialState: ScheduleTypeState = {
  isRequesting: false,
  errorMessage: '',
  scheduleType: undefined,
  partialScheduleTypes: [],
  allScheduleTypes: [],
  scheduleTypeWorkspaceId: undefined,
}

export const scheduleTypesSlice = createSlice({
  name: 'scheduleTypes',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getScheduleTypeListSuccess: (state, action: PayloadAction<API.ScheduleTypeListResponse>) => {
      state.isRequesting = false
      state.partialScheduleTypes = _.sortBy(action.payload.partialScheduleTypes, 'name')
      state.scheduleTypeWorkspaceId = action.payload.partialWorkspace.id
    },
    getScheduleTypeSuccess: (state, action: PayloadAction<API.ScheduleTypeResponse>) => {
      state.isRequesting = false
      state.scheduleType = action.payload.scheduleType
    },
    createScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    updateScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    deleteScheduleTypeSuccess: state => {
      state.isRequesting = false
    },
    getAllScheduleTypesSuccess: (state, action: PayloadAction<API.RelatedScheduleTypeListResponse>) => {
      state.isRequesting = false
      state.allScheduleTypes = _.sortBy(action.payload.partialScheduleTypes, 'name')
        .map(scheduleType => {
          const workspaceName =
            action.payload.partialWorkspaces.find(workspace => workspace.id === scheduleType.workspaceId)?.name || ''
          return { ...scheduleType, workspaceName }
        })
        .filter(scheduleType => scheduleType.workspaceName)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getScheduleTypeListSuccess,
  getScheduleTypeSuccess,
  createScheduleTypeSuccess,
  updateScheduleTypeSuccess,
  deleteScheduleTypeSuccess,
  getAllScheduleTypesSuccess,
} = scheduleTypesSlice.actions

export const getScheduleTypeList =
  (workspaceId: number, workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getScheduleTypeList(workspaceId, workDate)
      .then((res: API.ScheduleTypeListResponse) => dispatch(getScheduleTypeListSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getScheduleType =
  (workspaceId: number, scheduleTypeId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getScheduleType(workspaceId, scheduleTypeId)
      .then((res: API.ScheduleTypeResponse) => dispatch(getScheduleTypeSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const createScheduleType =
  (workspaceId: number, data: API.ScheduleTypeEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.createScheduleType(workspaceId, data)
      .then(res => {
        dispatch(createScheduleTypeSuccess())
        dispatch(getScheduleTypeList(workspaceId))
        dispatch(getScheduleType(workspaceId, res.id))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const updateScheduleType =
  (workspaceId: number, scheduleTypeId: number, data: API.ScheduleTypeEditDataType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.updateScheduleType(workspaceId, scheduleTypeId, data)
      .then(() => {
        dispatch(updateScheduleTypeSuccess())
        dispatch(getScheduleTypeList(workspaceId))
        dispatch(getScheduleType(workspaceId, scheduleTypeId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const deleteScheduleType =
  (workspaceId: number, scheduleTypeId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.deleteScheduleType(workspaceId, scheduleTypeId)
      .then(() => {
        dispatch(deleteScheduleTypeSuccess())
        dispatch(getScheduleTypeList(workspaceId))
      })
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const getRelatedScheduleType =
  (workDate?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    API.getRelatedScheduleType(workDate)
      .then((res: API.RelatedScheduleTypeListResponse) => dispatch(getAllScheduleTypesSuccess(res)))
      .catch((res: AxiosError) => {
        handleApiError(res, dispatch, apiFailure)
      })
  }

export const selectScheduleTypesStatus = (state: RootState) => ({ ...state.scheduleTypes })

export default scheduleTypesSlice.reducer
