import { unwrapResult } from '@reduxjs/toolkit'
import axios, { AxiosHeaders } from 'axios'
import { useEffect, useCallback } from 'react'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { asyncValidateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'

import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE, UNREACHABLE_ERROR_STATUS_CODE } from './api/utils'

import type { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import type { AppDispatch } from 'store'

const CLIENT_TYPES = {
  DEFAULT: 'DEFAULT',
  TENANT_ID: 'TENANT_ID',
  USER_ID: 'USER_ID',
} as const

type ClientType = (typeof CLIENT_TYPES)[keyof typeof CLIENT_TYPES]

type Props = {
  useAppDispatch: () => AppDispatch // 循環参照エラーを回避するためにインポートではなく引数で渡す
  children: React.ReactElement
}

// 使用時にパスがわかりにくいので､baseURLでclientを分ける
export const axiosClient = axios.create()
export const axiosClientWithTenantId = axios.create()
export const axiosClientWithTenantIdAndUserId = axios.create()

const shouldExcludeErrorDialog = (config: InternalAxiosRequestConfig | undefined) => {
  const getPlanByDate = { pattern: /.*\/workspaces\/\d+\/work-date\/[^/]+\/plan$/, method: 'get' }
  const updateTargetValue = { pattern: /.*\/workspaces\/\d+\/target-value$/, method: 'put' }
  const excludeErrorDialogList = [getPlanByDate, updateTargetValue]

  const { url, method } = config || {}

  if (excludeErrorDialogList.some(e => e.pattern.test(url ?? '') && e.method === method)) {
    return true
  }
  return false
}

export const AxiosClientProvider = ({ useAppDispatch, children }: Props) => {
  const dispatch = useAppDispatch()

  const setInterceptors = useCallback(
    (client: AxiosInstance, clientType: ClientType) => {
      console.log('AxiosClientProvider#setInterceptors')
      const a = client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        // useSelectorから取得したtokenが参照できないためasyncValidateTokenから渡す
        const result = await dispatch(asyncValidateToken())
        const sessionState = unwrapResult(result)

        if (!sessionState) {
          return Promise.reject(new Error('Invalid token'))
        }

        dispatch(Spinner.start())

        if (clientType === CLIENT_TYPES.DEFAULT) {
          config.baseURL = `${process.env.REACT_APP_API_SERVER}/api/v2`
        } else {
          const tenantId = sessionState.user.userHasTenants[0]?.id || 0
          if (clientType === CLIENT_TYPES.TENANT_ID) {
            config.baseURL = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${tenantId}`
          } else {
            config.baseURL = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${tenantId}/users/${sessionState.user.userId}`
          }
        }

        config.headers = new AxiosHeaders({
          ...config.headers,
          Authorization: sessionState.idToken,
          'X-Access-Authorization': sessionState.accessToken,
        })
        return config
      })

      const b = client.interceptors.response.use(
        (response: AxiosResponse) => {
          dispatch(Spinner.stop())
          return response
        },
        (error: AxiosError) => {
          dispatch(Spinner.stop())
          const errorCode = makeErrorMessage(error)

          if (shouldExcludeErrorDialog(error.config)) {
            return Promise.reject(error)
          } else if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
            dispatch(SessionTimeoutDialog.open())
          } else if (error.config?.method === 'get') {
            dispatch(NetworkErrorDialog.open({ code: errorCode }))
          } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
            dispatch(NetworkErrorDialog.open({ code: UNREACHABLE_ERROR_STATUS_CODE }))
          }
          return Promise.reject(error)
        }
      )
      return () => {
        client.interceptors.request.eject(a)
        client.interceptors.response.eject(b)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    console.log('AxiosClientProvider#useEffect')
    setInterceptors(axiosClient, CLIENT_TYPES.DEFAULT)
    setInterceptors(axiosClientWithTenantId, CLIENT_TYPES.TENANT_ID)
    setInterceptors(axiosClientWithTenantIdAndUserId, CLIENT_TYPES.USER_ID)
    return () => {
      // c()
    }
  }, [setInterceptors])

  return children
}
