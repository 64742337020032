import { axiosClient, axiosClientWithTenantId, axiosClientWithTenantIdAndUserId } from 'AxiosClientProvider'

import type { PartialTenantData, TenantData } from './tenants'
import type { Role } from './users/constants'

export type RoleType = (typeof Role)[keyof typeof Role]

export type UserListResponse = {
  partialTenants: PartialTenantData[]
  partialUsers: PartialUserData[]
}

export type UserResponse = {
  workDate: string
  tenants: TenantData[]
  user: UserData
}

export type PartialUserHasTenantsData = {
  revision: number
  id: number
  nickname: string | null
  isActive: boolean
  role: RoleType
  updatedAt: string
  updatedByName: string | null
}

export type PartialUserData = {
  userId: string
  isServiceManagementRole: boolean
  partialUserHasTenants: PartialUserHasTenantsData[]
}

export type UserHasTenantManagedWorkspace = {
  id: number
  name: string
  isManagement: boolean
}

export type FilteredValueScheduleType = {
  id: number
  isFilteredInSummary: boolean
  isFilteredInEachWorkspace: boolean
}

export type FilteredValue = {
  workspaceId: number
  scheduleTypes: FilteredValueScheduleType[]
}

export type UserHasTenantsType = PartialUserHasTenantsData & {
  filteredValue: FilteredValue
  language: string
  canViewBOP: boolean
  canManageBOP: boolean
  managedWorkspaces: UserHasTenantManagedWorkspace[]
}

export type UserData = {
  userId: string
  email: string
  isServiceManagementRole: boolean
  userHasTenants: UserHasTenantsType[]
  createdAt: string
  updatedAt: string | null
}

type EditDashboardFilterScheduleTypeProps = {
  id: number
  isFilteredInSummary?: boolean
  isFilteredInEachWorkspace?: boolean
}

type DashboardFilterScheduleTypeProps = {
  id: number
  isFilteredInSummary?: boolean
  isFilteredInEachWorkspace?: boolean
}

type DashboardFilterProps = {
  workspaceData: {
    id: number
    scheduleTypeData: DashboardFilterScheduleTypeProps[]
  }[]
}

type EditDashboardFilterProps = {
  workspaceData: {
    id: number
    scheduleTypeData: EditDashboardFilterScheduleTypeProps[]
  }[]
}

type BOPDashboardFilterTypeProps = {
  id: number
  isFilteredInSummary: boolean
  scheduleTypeData: {
    id: number
    isFilteredInUnitCosts: boolean
  }[]
}

type BOPDashboardFilterProps = {
  workspaceData: BOPDashboardFilterTypeProps[]
}

type BOPReportTypeProps = {
  id: number
  isFilteredInSummary: boolean
  isFilteredInUnitCosts: boolean
  isFilteredInLaborCosts: boolean
  scheduleTypeData: {
    id: number
    isFilteredInUnitCosts: boolean
  }[]
}

type BOPReportProps = {
  workspaceData: BOPReportTypeProps[]
}

export type DisplayFilterResponse = {
  revision: number
  dashboard: DashboardFilterProps
  bopDashboard: BOPDashboardFilterProps
  bopReport: BOPReportProps
  updatedAt: string
  updatedByName: string
}

export type CreateUserProps = {
  email: string
}

export type UpdateUserProps = {
  email?: string
  isServiceManagementRole?: boolean
  nickname?: string
}

export type UpdateUserTenantProps = {
  role?: RoleType
  nickName?: string
  isActive: boolean
}

export type CreateTenantUserProps = {
  email: string
  role?: RoleType
}

export type UpdateTenantUserProps = {
  email?: string
  nickname?: string
  language?: string
}

type EditUserManagedWorkspaces = {
  id: number
  isManagement: boolean
}

export type UpdateUserPermissionProps = {
  role?: RoleType
  canViewBOP?: boolean
  canManageBOP?: boolean
  managedWorkspaces?: EditUserManagedWorkspaces[]
}

export type UpdateDisplayFilterProps = {
  dashboard?: EditDashboardFilterProps
  bopDashboard?: BOPDashboardFilterProps
  bopReport?: BOPReportProps
}

export const getUserList = (): Promise<UserListResponse> => {
  return new Promise((resolve, reject) => {
    const url = '/users'

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createUser = (data: CreateUserProps): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = '/users'

    axiosClient
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getUser = (userId: string): Promise<UserResponse> => {
  return new Promise((resolve, reject) => {
    const url = `/users/${userId}`

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateUser = (userId: string, data: UpdateUserProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `/users/${userId}`

    axiosClient
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteUser = (userId: string): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `/users/${userId}`

    axiosClient
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserTenant = (
  userId: string,
  tenantId: number,
  data: UpdateUserTenantProps
): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `/users/${userId}/tenants/${tenantId}`

    axiosClient
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUserList = (tenantId: number): Promise<UserListResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/users`

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createTenantUser = (tenantId: number, data: CreateTenantUserProps): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/users`

    axiosClient
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUser = (tenantId: number | undefined, userId: string): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/users/${userId}`

    axiosClient
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantUser = (data: UpdateTenantUserProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    axiosClientWithTenantIdAndUserId
      .patch('', data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteTenantUser = (tenantId: number, userId: string): Promise<{ userId: string }> =>
  new Promise((resolve, reject) => {
    const url = `/tenants/${tenantId}/users/${userId}`

    axiosClient
      .delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantUserPermission = (userId: string, data: UpdateUserPermissionProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `/users/${userId}/permission`

    axiosClientWithTenantId
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getDisplayFilter = (): Promise<DisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = '/display-filter'

    axiosClientWithTenantIdAndUserId
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateDisplayFilter = (data: UpdateDisplayFilterProps): Promise<DisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = '/display-filter'

    axiosClientWithTenantIdAndUserId
      .patch(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
